import React from 'react'
import PT from 'prop-types'
import { Flex } from '../../../ui-kit'
import { BuyerQuery } from '../../../queries/buyers.gql'
import CompanyContacts from './contactInfoSections/CompanyContacts'
import GeneralInfo from './contactInfoSections/GeneralInfo'
import AssignedReps from './contactInfoSections/AssignedReps'
import { useCustomQuery } from '../../../hooks/useCustomQuery'

const ContactInfo = ({ contractData }) => {
  const buyerVariables = {
    id: contractData?.buyer?.id,
    withAddresses: true,
  }

  const {
    data: buyerData,
    loading: buyerLoading,
    refetch,
  } = useCustomQuery({
    query: BuyerQuery,
    queryOptions: {
      variables: buyerVariables,
      skip: !buyerVariables.id,
    },
    rollbarOptions: { operationName: 'BuyerQuery', target: 'ContactInfo' },
  })

  if (buyerLoading) return null

  return (
    <Flex className="mt-4" column>
      <Flex>
        <GeneralInfo buyerData={buyerData} buyerLoading={buyerLoading} />
        <AssignedReps contractData={contractData} />
      </Flex>
      <CompanyContacts
        buyerData={buyerData}
        buyerLoading={buyerLoading}
        contractId={contractData?.id}
        refetch={refetch}
      />
    </Flex>
  )
}

ContactInfo.propTypes = {
  contractData: PT.shape({
    id: PT.string,
    buyer: PT.shape({
      id: PT.string.isRequired,
    }),
    vendor: PT.shape({
      id: PT.string.isRequired,
    }),
  }).isRequired,
}

export default ContactInfo
