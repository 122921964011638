import React, { useEffect, useState } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import Invoices from '../invoices/Invoices'
import ContractIndex from './ContractIndex'
import ContractHeader from './contractHeader'
import { Documents } from './index'
import { CustomerInfo } from './index'
import { ContractQuery } from '../../queries/contracts.gql'
import { LoadingSpinner } from '../../ui-kit'
import Payments from '../payments/Payments'
import ContractOverview from './contractOverview/ContractOverview'
import ContractSettings from './contractSettings/ContractSettings'
import { ContractNotes } from '../../queries/contractNotes.gql'
import Projects from '../projects/Projects'
import ContractActivities from './contractActivities/ContractActivities'
import { useCustomQuery } from '../../hooks/useCustomQuery'

const ContractDetail = () => {
  const { contractId } = useParams()
  const { data, loading, refetch } = useCustomQuery({
    query: ContractQuery,
    queryOptions: {
      variables: { id: contractId },
      notifyOnNetworkStatusChange: false,
    },
    rollbarOptions: { operationName: 'ContractQuery', target: 'ContractDetail' },
  })
  const contractData = data?.contract

  const { data: notesData, loading: areNotesLoading } = useCustomQuery({
    query: ContractNotes,
    queryOptions: {
      variables: { id: contractId },
    },
    rollbarOptions: { operationName: 'ContractNotes', target: 'ContractDetail' },
  })

  const [notes, setNotes] = useState(notesData?.notes?.data)
  useEffect(() => {
    setNotes(notesData?.notes?.data)
  }, [notesData?.notes?.data])

  if (loading) return <LoadingSpinner loading={loading} />
  return (
    <>
      <ContractHeader contractData={contractData} contractId={contractId} />

      <Routes>
        <Route element={<ContractIndex contractId={contractId} />} path="/" />
        <Route
          element={
            <ContractOverview
              areNotesLoading={areNotesLoading}
              contractId={contractId}
              contractName={contractData?.buyer?.name}
              notes={notes}
              overdueLevelGroups={contractData?.overdueLevelGroups}
            />
          }
          path={'/overview'}
        />
        <Route
          element={
            <ContractSettings contractId={contractId} contractName={contractData?.buyer.name} />
          }
          path={'/settings/*'}
        />

        <Route
          element={
            <Invoices
              contractData={contractData}
              contractId={contractId}
              refetchContract={refetch}
            />
          }
          path="/invoices/*"
        />

        <Route
          element={<Projects contractData={contractData} contractId={contractId} />}
          path="/projects/*"
        />

        <Route element={<CustomerInfo contractData={contractData} />} path="/customer_info/*" />
        <Route
          element={
            <ContractActivities
              contractData={contractData}
              contractId={contractId}
              contractName={contractData?.buyer?.name}
            />
          }
          path="/activity/*"
        />

        <Route
          element={<Documents contractData={contractData} contractId={contractId} />}
          path="/documents"
        />
        <Route element={<Payments contractData={contractData} />} path="/payments/*" />
      </Routes>
    </>
  )
}

export default ContractDetail
