import React from 'react'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import { Money } from '../../../ui-kit'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import { statusP2PMapping } from '../../payments/promiseToPay/util'

export const getCustomerPaymentsFiltersColumns = (t, loadFilter) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'entryDate',
    filterTitle: t('date'),
  },
  {
    filterId: 'payableType',
    filterTitle: t('type'),
  },
  {
    filterId: 'paymentMethodType',
    filterTitle: t('paymentMethod'),
  },
  {
    filterId: 'depositedTo',
    filterTitle: t('depositedTo'),
  },
  {
    filterId: 'contractId',
    filterTitle: t('customer'),
    loadOptions: loadFilter?.loadContractOptions,
  },
  {
    filterId: 'createdBy',
    filterTitle: t('createdBy'),
    loadOptions: loadFilter?.loadVendorUsersOptions,
  },
  {
    filterId: 'status',
    filterTitle: t('paymentStatus'),
    filterOptions: [
      {
        key: 'pending',
        title: 'Pending',
      },
      {
        key: 'successful',
        title: 'Successful',
      },
      {
        key: 'failed',
        title: 'Failed',
      },
      {
        key: 'voided',
        title: 'Voided',
      },
      {
        key: 'chargeback',
        title: 'Chargeback',
      },
    ],
  },
  {
    filterId: 'homeLocation',
    filterTitle: t('homeLocation'),
  },
]

export const getCustomerPaymentsColumns = (t) => [
  {
    field: 'entryDate',
    headerName: t('date'),
    renderCell: (values) => <FormattedDate date={values?.row?.entryDate} />,
    flex: 10,
  },
  {
    field: 'id',
    headerName: t('id'),
    flex: 10,
  },
  {
    field: 'buyerName',
    headerName: t('customer'),
    flex: 25,
  },
  {
    field: 'homeLocation',
    headerName: t('homeLocation'),
    renderCell: (values) => values?.row?.homeLocation || t('Unassigned'),
    flex: 15,
  },
  {
    field: 'contractId',
    headerName: t('customerId'),
    flex: 10,
  },
  {
    field: 'type',
    headerName: t('type'),
    width: 80,
  },
  {
    field: 'amountCents',
    headerName: t('totalPayment'),
    renderCell: (values) => <Money value={values?.row?.amountCents} />,
    width: 108,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'chargeAmountCents',
    headerName: t('charged'),
    renderCell: (values) => <Money value={values?.row?.chargeAmountCents} />,
    width: 108,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'paymentMethod',
    headerName: t('paymentMethod'),
    flex: 15,
  },
  {
    field: 'financialEntity',
    headerName: t('depositedTo'),
    flex: 15,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.financialEntity || '-'}</span>
    ),
  },
  {
    field: 'paymentStatus',
    headerName: t('paymentStatus'),
    flex: 10,
    renderCell: (values) => t(values?.row?.paymentStatus),
  },
  {
    field: 'memo',
    headerName: t('memo'),
    flex: 10,
    sortable: false,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.memo}</span>
    ),
  },
]

export const getCustomerPaymentsTotalItems = (total) => [
  { captionKey: 'numberOfPayments', value: total?.numberOfPayments || 0, isPlainText: true },
  { captionKey: 'totalPayment', value: total?.amountCents },
  { captionKey: 'charged', value: total?.chargeAmountCents },
]

export const getAuthorizationsFiltersColumns = (t, loadFilter) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'createdAt',
    filterTitle: t('authDate'),
  },
  {
    filterId: 'contractId',
    filterTitle: t('customer'),
    loadOptions: loadFilter?.loadContractOptions,
  },
  {
    filterId: 'createdBy',
    filterTitle: t('createdBy'),
    loadOptions: loadFilter?.loadVendorUsersOptions,
  },
  {
    filterId: 'homeLocation',
    filterTitle: t('homeLocation'),
  },
]

export const getAuthorizationsColumns = (t) => [
  {
    field: 'date',
    headerName: t('authDate'),
    renderCell: (values) => <FormattedDate date={values?.row?.date} />,
    flex: 10,
  },
  {
    field: 'id',
    headerName: t('id'),
    flex: 10,
  },
  {
    field: 'buyerName',
    headerName: t('customer'),
    flex: 25,
  },
  {
    field: 'homeLocation',
    headerName: t('homeLocation'),
    renderCell: (values) => values?.row?.homeLocation || t('Unassigned'),
    flex: 15,
  },
  {
    field: 'contractId',
    headerName: t('customerId'),
    flex: 10,
  },
  {
    field: 'type',
    headerName: t('type'),
    flex: 10,
  },
  {
    field: 'amountCents',
    headerName: t('totalPayment'),
    renderCell: (values) => <Money value={values?.row?.amountCents} />,
    flex: 10,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'chargeAmountCents',
    headerName: t('charged'),
    renderCell: (values) => <Money value={values?.row?.chargeAmountCents} />,
    flex: 10,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'paymentMethod',
    headerName: t('paymentMethod'),
    flex: 15,
  },
  {
    field: 'paymentStatus',
    headerName: t('paymentStatus'),
    flex: 10,
    renderCell: (values) => t(values?.row?.paymentStatus),
  },
  {
    field: 'memo',
    headerName: t('memo'),
    flex: 10,
    sortable: false,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.memo}</span>
    ),
  },
]

export const getAuthorizationsTotalItems = (total) => [
  { captionKey: 'numberOfPayments', value: total?.numberOfPayments || 0, isPlainText: true },
  { captionKey: 'amount', value: total?.amountCents },
  { captionKey: 'charged', value: total?.chargeAmountCents },
]

export const getPaymentPlansFiltersColumns = (t, loadFilter) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'createdAt',
    filterTitle: t('date'),
  },
  {
    filterId: 'lastPayment',
    filterTitle: t('lastPaymentDate'),
  },
  {
    filterId: 'nextPayment',
    filterTitle: t('nextPaymentDate'),
  },
  {
    filterId: 'contractId',
    filterTitle: t('customer'),
    loadOptions: loadFilter?.loadContractOptions,
  },
  {
    filterId: 'createdBy',
    filterTitle: t('createdBy'),
    loadOptions: loadFilter?.loadVendorUsersOptions,
  },
  {
    filterId: 'homeLocation',
    filterTitle: t('homeLocation'),
  },
]

export const getPaymentPlansColumns = (t) => [
  {
    field: 'createdAt',
    headerName: t('date'),
    renderCell: (values) => <FormattedDate date={values?.row?.createdAt} />,
    flex: 10,
  },
  {
    field: 'planNumber',
    headerName: t('planId'),
    flex: 10,
  },
  {
    field: 'buyerName',
    headerName: t('customer'),
    flex: 25,
  },
  {
    field: 'homeLocation',
    headerName: t('homeLocation'),
    renderCell: (values) => values?.row?.homeLocation || t('Unassigned'),
    flex: 15,
  },
  {
    field: 'customerId',
    headerName: t('customerId'),
    flex: 10,
  },
  {
    field: 'totalAmount',
    headerName: t('totalAmount'),
    renderCell: (values) => <Money value={values?.row?.totalAmount} />,
    flex: 10,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'installments',
    headerName: t('installments'),
    flex: 10,
  },
  {
    field: 'lastPaymentDate',
    headerName: t('lastPaymentDate'),
    renderCell: (values) => <FormattedDate date={values?.row?.lastPaymentDate} />,
    flex: 15,
  },
  {
    field: 'lastPaymentAmount',
    headerName: t('lastPaymentAmount'),
    renderCell: (values) => <Money value={values?.row?.lastPaymentAmount} />,
    flex: 15,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'nextPaymentDate',
    headerName: t('nextPaymentDate'),
    renderCell: (values) => <FormattedDate date={values?.row?.nextPaymentDate} />,
    flex: 15,
  },
  {
    field: 'nextPaymentAmount',
    headerName: t('nextPaymentAmount'),
    renderCell: (values) => <Money value={values?.row?.nextPaymentAmount} />,
    flex: 15,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'status',
    headerName: t('status'),
    flex: 10,
    renderCell: (values) => t(values?.row?.status),
  },
]

export const getPaymentPlansTotalItems = (total) => [{ captionKey: 'amount', value: total?.amount }]

export const getPromiseToPaysFiltersColumns = (t, loadFilter) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'createdAt',
    filterTitle: t('date'),
  },
  {
    filterId: 'date',
    filterTitle: t('expPmtDate'),
  },
  {
    filterId: 'contractId',
    filterTitle: t('customer'),
    loadOptions: loadFilter?.loadContractOptions,
  },
  {
    filterId: 'createdBy',
    filterTitle: t('createdBy'),
    loadOptions: loadFilter?.loadVendorUsersOptions,
  },
  {
    filterId: 'homeLocation',
    filterTitle: t('homeLocation'),
  },
]

export const getPromiseToPaysColumns = (t) => [
  {
    field: 'createdAt',
    headerName: t('date'),
    renderCell: (values) => <FormattedDate date={values?.row?.createdAt} />,
    flex: 10,
  },
  {
    field: 'id',
    headerName: t('p2pId'),
    flex: 10,
  },
  {
    field: 'buyerName',
    headerName: t('customer'),
    flex: 30,
  },
  {
    field: 'homeLocation',
    headerName: t('homeLocation'),
    renderCell: (values) => values?.row?.homeLocation || t('Unassigned'),
    flex: 15,
  },
  {
    field: 'contractId',
    headerName: t('customerId'),
    flex: 10,
  },
  {
    field: 'amountCents',
    headerName: t('amount'),
    renderCell: (values) => <Money value={values?.row?.amountCents} />,
    flex: 10,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'date',
    headerName: t('expPmtDate'),
    renderCell: (values) => <FormattedDate date={values?.row?.date} />,
    flex: 10,
  },
  {
    field: 'status',
    headerName: t('status'),
    flex: 10,
    renderCell: (values) => (
      <StatusBadge
        color={statusP2PMapping[values.row.status]?.color}
        value={t(statusP2PMapping[values.row.status]?.label)}
      />
    ),
  },
  {
    field: 'memo',
    headerName: t('memo'),
    flex: 10,
    sortable: false,
    renderCell: (values) => (
      <span className={'text-ellipsis overflow-hidden'}>{values?.row?.memo}</span>
    ),
  },
]

export const getPromiseToPaysTotalItems = (total) => [
  { captionKey: 'amount', value: total?.amountCents },
]

export const getRefundsFiltersColumns = (t, loadFilter) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'createdAt',
    filterTitle: t('refundDate'),
  },
  {
    filterId: 'contractId',
    filterTitle: t('customer'),
    loadOptions: loadFilter?.loadContractOptions,
  },
  {
    filterId: 'createdBy',
    filterTitle: t('createdBy'),
    loadOptions: loadFilter?.loadVendorUsersOptions,
  },
  {
    filterId: 'homeLocation',
    filterTitle: t('homeLocation'),
  },
]

export const getRefundsColumns = (t) => [
  {
    field: 'createdAt',
    headerName: t('refundDate'),
    renderCell: (values) => <FormattedDate date={values?.row?.createdAt} />,
    flex: 5,
    sortable: false,
  },
  {
    field: 'sequentialId',
    headerName: t('refundId'),
    renderCell: (values) => values?.row?.sequentialId,
    flex: 5,
    sortable: false,
  },
  {
    field: 'refundType',
    headerName: t('refundType'),
    renderCell: (values) => values?.row?.refundType,
    flex: 5,
    sortable: false,
  },
  {
    field: 'buyerName',
    headerName: t('customer'),
    renderCell: (values) => values?.row?.buyerName,
    flex: 20,
    sortable: false,
  },
  {
    field: 'homeLocation',
    headerName: t('homeLocation'),
    renderCell: (values) => values?.row?.homeLocation || t('Unassigned'),
    flex: 15,
  },
  {
    field: 'customerId',
    headerName: t('customerId'),
    renderCell: (values) => values?.row?.customerId,
    flex: 10,
    sortable: false,
  },
  {
    field: 'relatedEntityId',
    headerName: t('invoiceOrDepositId'),
    flex: 5,
    sortable: false,
  },
  {
    field: 'amountCents',
    headerName: t('refundAmount'),
    renderCell: (values) => <Money value={values?.row?.amountCents} />,
    flex: 10,
    align: 'right',
    headerAlign: 'right',
    sortable: false,
  },
  {
    field: 'paymentMethod',
    headerName: t('paymentMethod'),
    flex: 10,
    sortable: false,
  },
  {
    field: 'memo',
    headerName: t('memo'),
    renderCell: (values) => (
      <span className={'text-ellipsis overflow-hidden'}>{values?.row?.memo}</span>
    ),
    flex: 10,
    sortable: false,
  },
]

export const getRefundsReportData = (response) => {
  if (!response?.data) {
    return []
  }

  return response.data.reduce((acc, refund) => {
    const transactions = refund?.transactions || []
    const mergedRows = transactions.map((transaction, index) => ({
      ...refund,
      ...transaction,
      isFirstTransaction: index === 0,
    }))
    acc.push(...mergedRows)

    return acc
  }, [])
}

export const getRefundsTotalItems = (total) => [
  { captionKey: 'refundAmount', value: total?.refundAmount },
]
