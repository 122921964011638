import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  Button,
  ConfirmationDialog,
  Flex,
  NarrowList,
  Section,
  StatusBadge,
  Text,
  Dialog,
  Input,
  Heading,
  LoadingSpinner,
  FormattedDate,
  Money,
} from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import NewPaymentMethod from '../../../components/paymentType/NewPaymentMethod'
import { getPaymentTypesOptions } from '../../invoices/invoicesUtils'
import PT from 'prop-types'
import { PaymentMethodsQuery } from '../../../queriesUpdated/queries/paymentMethod.gql'
import { paymentMethodsEligibilityEnum } from '../../../constants/paymentMethodEligibilityEnum'
import PaymentMethodDetails from './PaymentMethodDeatils'
import paymentMethodType from '../../../types/paymentMethod'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { paymentMethodTypes } from '../../../constants/paymentResults'
import Sidebar from '../../../ui-kit/components/sidebar/Sidebar'
import { DeletePaymentMethod } from '../../../queries/mutations/deletePaymentMethod.gql'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { UpdatePaymentMethodMutation } from '../../../queries/mutations/updatePaymentMethod.gql'
import { Field, Form } from 'react-final-form'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import { ContractAutopayConfiguration } from '../../../queries/contracts.gql'
import { upperCase } from 'lodash'
import AutopayModalContent from './autopay/AutopayModalContent'
import { getDayWithSuffix } from '../../../utils/getSuffix'

const PaymentProfile = ({ contractData }) => {
  const { t } = useTranslation()
  const [paymentTypes, setPaymentTypes] = useState([])
  const [isModalOpened, seIsModalOpened] = useState(false)
  const [isUpdateModalOpened, seIsUpdateModalOpened] = useState(false)
  const [autopayModalOpened, setAutopayModalOpened] = useState(false)
  const hideAutopayModal = () => setAutopayModalOpened(false)

  const {
    data: paymentMethodsData,
    loading: paymentQueryInProgress,
    refetch,
  } = useCustomQuery({
    query: PaymentMethodsQuery,
    queryOptions: {
      variables: {
        contractId: contractData.id,
        eligibility: paymentMethodsEligibilityEnum.PAYMENT_PLAN,
        includeMetaInfo: true,
      },
      skip: !contractData.id,
    },
    rollbarOptions: { operationName: 'PaymentMethodsQuery', target: 'PaymentProfile' },
  })

  const paymentMethodsItems = useMemo(
    () =>
      (paymentMethodsData?.paymentMethods || []).filter(
        (method) =>
          method.type === paymentMethodTypes.CREDIT_CARD_PAYMENT_METHOD ||
          method.type === paymentMethodTypes.ACH_PAYMENT_METHOD,
      ),
    [paymentMethodsData?.paymentMethods],
  )

  useEffect(() => {
    if (!paymentQueryInProgress && paymentMethodsData) {
      setPaymentTypes(
        getPaymentTypesOptions(paymentMethodsData, t).filter((item) => !item.isHidden),
      )
    }
  }, [t, paymentQueryInProgress, paymentMethodsData])
  const {
    data: contractAutopayQueryData,
    refetch: refetchAutopay,
    loading: autopayLoading,
  } = useCustomQuery({
    query: ContractAutopayConfiguration,
    queryOptions: {
      variables: { id: contractData?.id },
      skip: !contractData?.id,
    },
    rollbarOptions: { operationName: 'ContractsAutopayQuery', target: 'AutopayCard' },
  })
  const autopayEnabled = !!contractAutopayQueryData?.contract?.autopayConfiguration
  const contractPaymentSettings = contractAutopayQueryData?.contract?.paymentSettings
  const autopayConfiguration = contractAutopayQueryData?.contract?.autopayConfiguration || {}
  const { day, lastPayment, nextPaymentDate, paymentMethod } = autopayConfiguration
  const autopayPaymentTypeId = paymentMethod?.id

  const handleCreateMethod = useCallback(() => {
    refetch()
    seIsModalOpened(false)
  }, [refetch])
  const onCompleted = useCallback(() => {
    refetch()
    refetchAutopay()
    setIsSidebarIsOpened(false)
  }, [])
  const [deletePaymentMethod, { loading }] = useCustomMutation({
    mutation: DeletePaymentMethod,
    onCompleted,
    rollbarOptions: { operationName: 'DeletePaymentMethod', target: 'PaymentProfile' },
  })
  const [confirmDeleteModalOpened, setConfirmDeleteModalOpened] = useState(false)
  const [updatePaymentMethod, { loading: isUpdateLoading }] = useCustomMutation({
    mutation: UpdatePaymentMethodMutation,
    onCompleted,
    rollbarOptions: {
      operationName: 'UpdatePaymentMethodMutation',
      target: 'PaymentProfile',
    },
  })
  const onDeletePaymentMethod = (id) => {
    deletePaymentMethod({
      variables: {
        id,
      },
    })
    setConfirmDeleteModalOpened(false)
    setIsSidebarIsOpened(false)
  }

  const flatTypes = paymentTypes.flatMap((type) => {
    if (type.options.length) {
      return type.options
    } else {
      return type
    }
  })
  const [isSidebarIsOpened, setIsSidebarIsOpened] = useState(false)

  const onCancelDeleteClick = () => {
    setConfirmDeleteModalOpened(false)
    setIsSidebarIsOpened(true)
  }

  const [selectedMethod, setSelectedMethod] = useState({})
  const initialValues = useMemo(
    () => ({
      nickname: selectedMethod?.nickname || '',
    }),
    [selectedMethod],
  )
  const onMethodClick = (method) => {
    setSelectedMethod(method)
    setIsSidebarIsOpened(true)
  }

  const items = flatTypes.map((type) => (
    <div
      className={`grid w-full ${
        paymentMethod ? 'grid-cols-6 gap-2' : 'grid-cols-5 gap-4'
      } justify-between text-sm`}
      key={type.id}
      onClick={() => onMethodClick(type)}
      testdata={`pm-${type.id}`}>
      <div className="font-medium">{type.title}</div>
      <div className="text-black-500" testdata="nickname">
        {type.nickname || '-'}
      </div>
      <div className="text-black-500">
        {type.type === paymentMethodTypes.CREDIT_CARD_PAYMENT_METHOD
          ? t('card')
          : t('bankTransfer')}
      </div>
      <div className="text-black-500">
        {type.data?.['exp-date'] && (
          <>{`${t('expDate')}: ${type.data['exp-date'].substr(0, 2)}/${type.data['exp-date'].substr(
            2,
            2,
          )}`}</>
        )}
      </div>
      <div
        className="text-primary-700 font-medium text-xs text-right justify-end items-center"
        testdata="default">
        {type.default ? t('default') : ''}
      </div>
      {paymentMethod && (
        <div
          className="text-primary-700 font-medium text-xs text-right justify-end items-center"
          testdata="autopay">
          {autopayPaymentTypeId === type.id && <StatusBadge color="orange" value={t('autopay')} />}
        </div>
      )}
    </div>
  ))

  const handleUpdateSubmit = (values) => {
    updatePaymentMethod({
      variables: {
        id: selectedMethod?.id,
        nickname: values.nickname || '',
      },
    })
    seIsUpdateModalOpened(false)
    setIsSidebarIsOpened(false)
  }
  const onCancelUpdateClick = () => {
    seIsUpdateModalOpened(false)
    setIsSidebarIsOpened(true)
  }

  const getRunOnMsg = (day) => {
    if (day) {
      switch (day) {
        case 'last_day_of_month':
          return t('lastDayOfMonth')
        case 'as_due':
          return t('payWhenDue')
        default:
          return t('nthDayMsg', { day: getDayWithSuffix(day) })
      }
    }
  }

  const renderAutopayItems = () => {
    const items = [
      {
        label: (
          <Text color="text-gray-500" size={sizes.SM}>
            {t('status')}
          </Text>
        ),
        value: (
          <StatusBadge
            color={autopayEnabled ? 'green' : 'red'}
            value={autopayEnabled ? upperCase(t('on')) : upperCase(t('off'))}
          />
        ),
      },
    ]
    if (autopayEnabled) {
      items.push(
        {
          label: <Text color="text-gray-500">{t('paymentMethod')}</Text>,
          value: <Text>{paymentMethod.title}</Text>,
        },
        {
          label: <Text color="text-gray-500">{t('runOn')}</Text>,
          value: <Text>{getRunOnMsg(day)}</Text>,
        },
        {
          label: <Text color="text-gray-500">{t('nextAutopayDate')}</Text>,
          value: nextPaymentDate ? (
            <FormattedDate date={nextPaymentDate} format={'MM/dd/yyyy'} />
          ) : (
            <Text>{t('asInvoicesComeDue')}</Text>
          ),
        },
        {
          label: <Text color="text-gray-500">{t('lastAutopayDate')}</Text>,
          value: (
            <div>
              {lastPayment?.paymentTransaction?.entryDate ? (
                <>
                  <a
                    className={'blue'}
                    /* eslint-disable-next-line max-len */
                    href={`/customers/${contractData?.id}/payments/payments?additionalFilters=id&filters=id.${lastPayment?.paymentTransaction?.id}&openSidebarParams=id.${lastPayment?.paymentTransaction?.id}`}>
                    {t('id')}: {lastPayment?.paymentTransaction?.id}
                  </a>
                  &nbsp;|&nbsp;
                  <FormattedDate
                    date={lastPayment?.paymentTransaction?.entryDate}
                    format={'MM/dd/yyyy'}
                  />
                </>
              ) : (
                '-'
              )}
            </div>
          ),
        },
        {
          label: <Text color="text-gray-500">{t('lastAutopayAmount')}</Text>,
          value: <Money value={lastPayment?.totalAmountCents} />,
        },
      )
    }
    return items.map((item) => {
      return (
        <Flex className="w-full" key={item.title}>
          <div className="w-1/2">{item.label}</div>
          <div className="w-1/2 flex justify-end">{item.value}</div>
        </Flex>
      )
    })
  }

  return (
    <>
      <Sidebar isSidebarOpened={isSidebarIsOpened} setIsSidebarOpened={setIsSidebarIsOpened}>
        <PaymentMethodDetails
          autopayPaymentTypeId={autopayPaymentTypeId}
          data={selectedMethod}
          refetch={refetch}
          seIsUpdateModalOpened={seIsUpdateModalOpened}
          setConfirmDeleteModalOpened={setConfirmDeleteModalOpened}
          setIsSidebarOpened={setIsSidebarIsOpened}
        />
      </Sidebar>

      <Flex className="w-full space-x-3 mt-4">
        <Section className="w-8/12">
          <Flex className="w-full">
            <div className="w-9/12">
              <Heading
                className="mb-4"
                fontWeight={fontWeight.THIN}
                isBold={false}
                size={sizes.XL2}>
                {t('paymentMethods')}
              </Heading>
            </div>
            <div className="w-3/12 text-right">
              <Button
                iconName={'plusCircle'}
                iconType={'outline'}
                label={t('addNew')}
                onClick={() => seIsModalOpened(true)}
                testData="plus-add-pm"
                variant={buttonsVariants.SECONDARY}
              />
            </div>
          </Flex>
          <Flex className="w-full">
            <Text color="text-warmBlack-400" size={sizes.SM}>
              {t('addPaymentMethodsDescription')}
            </Text>
          </Flex>
          <Flex className="w-full pt-2">
            <NarrowList className="w-full" listItems={items} withHoverEffect />
          </Flex>
        </Section>
        <Section className="w-4/12">
          {autopayLoading && <LoadingSpinner className="w-full h-full" />}
          {!autopayLoading && (
            <>
              <Flex className="w-full">
                <div className="w-8/12">
                  <Heading
                    className="mb-4"
                    fontWeight={fontWeight.THIN}
                    isBold={false}
                    size={sizes.XL2}>
                    {t('Autopay')}
                  </Heading>
                </div>
                <div className="w-4/12 text-right">
                  <Button
                    iconName={'pencil'}
                    iconType={'outline'}
                    label={t('edit')}
                    onClick={() => setAutopayModalOpened(true)}
                    variant={buttonsVariants.TERTIARY}
                  />
                </div>
              </Flex>
              <Flex className="w-full">
                <Text color="text-warmBlack-400" size={sizes.SM}>
                  {t('editAutopayDescription')}
                </Text>
              </Flex>
              <Flex className="w-full pt-2">
                <NarrowList className="w-full" listItems={renderAutopayItems()} withHoverEffect />
              </Flex>
            </>
          )}
        </Section>
      </Flex>
      <NewPaymentMethod
        contractID={contractData?.id}
        isOpened={isModalOpened}
        onCreateMethod={handleCreateMethod}
        setIsOpened={seIsModalOpened}
      />
      <Dialog
        isOpened={autopayModalOpened}
        setIsOpened={setAutopayModalOpened}
        title={t('manageAutopay')}>
        <AutopayModalContent
          autopayConfiguration={autopayConfiguration}
          autopayEnabled={autopayEnabled}
          contractData={contractData}
          contractPaymentSettings={contractPaymentSettings}
          hideModal={hideAutopayModal}
          paymentMethodsData={{ paymentMethods: paymentMethodsItems }}
          refetchPaymentMethods={refetch}
        />
      </Dialog>
      <ConfirmationDialog
        confirmationMessage={`${t('remove')} ${selectedMethod?.title}?`}
        isOpened={confirmDeleteModalOpened}
        onModalClose={() => setIsSidebarIsOpened(true)}
        setIsOpened={setConfirmDeleteModalOpened}
        title={t('removePaymentMethod')}
        withCustomButtons>
        <Flex className="pt-4" column>
          <Flex className="mb-6" column>
            <Text color="text-black-500">{t('activePendingPaymentsWillContinueToProcess')}</Text>
            <Text className="mt-4" color="text-black-500">
              {t('futureInstallmentsWillBeCancelled')}
            </Text>
            {selectedMethod?.id === autopayPaymentTypeId && (
              <Text className="mt-4" color="text-black-500">
                {t('unenrolledAutopayWarning')}
              </Text>
            )}
          </Flex>
          <Flex column>
            <Button
              className="mb-4"
              disabled={loading}
              label={t('remove')}
              onClick={() => onDeletePaymentMethod(selectedMethod?.id)}
              testData="confirm-yes"
            />
            <Button
              label={t('cancel')}
              onClick={onCancelDeleteClick}
              testData="confirm-no"
              variant="tertiary"
            />
          </Flex>
        </Flex>
      </ConfirmationDialog>
      <Dialog
        isOpened={isUpdateModalOpened}
        setIsOpened={seIsUpdateModalOpened}
        title={t('updateNickname')}>
        <Form
          initialValues={initialValues}
          onSubmit={handleUpdateSubmit}
          render={({ handleSubmit, submitError }) => {
            return (
              <form className="flex flex-col pt-4" onSubmit={handleSubmit}>
                <Field name="nickname">
                  {({ input, meta }) => {
                    return (
                      <Input
                        {...input}
                        className="pb-6"
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t(input.name)}
                        maxLength={16}
                        placeholder={t('nickname')}
                        testData="update-pm-nickname-input"
                      />
                    )
                  }}
                </Field>
                {submitError && <span className="error leading-8">{submitError}</span>}
                <Flex justifyContent="end">
                  <Button
                    className="mr-4"
                    label={t('cancel')}
                    onClick={onCancelUpdateClick}
                    testData="cancel-update-pm-nickname"
                    variant={buttonsVariants.TERTIARY}
                  />
                  <Button
                    disabled={!!isUpdateLoading}
                    label={t('save')}
                    testData="submit-update-pm-nickname"
                    type="submit"
                  />
                </Flex>
              </form>
            )
          }}
        />
      </Dialog>
    </>
  )
}

export default PaymentProfile

PaymentProfile.propTypes = {
  contractData: PT.shape({
    aging: PT.number,
    autopayEnabled: PT.bool,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(paymentMethodType),
    }),
  }),
}

PaymentProfile.defaultProps = {
  contractData: {},
}
