import React, { useState, useEffect } from 'react'
import PageHeader from '../../../components/headers/PageHeader'
import { useTranslation } from 'react-i18next'
import Section from '../../../ui-kit/components/section'
import { Button, Flex, LoadingSpinner, Tabs, Text } from '../../../ui-kit'
import PT from 'prop-types'
import { UpdateContractPaymentSettingRequest } from '../../../queries/mutations/updateContractPaymentSettings.gql'
import { DeleteContractPaymentSettingRequest } from '../../../queries/mutations/deleteContractPaymentSettings.gql'
import { useNotifications } from '../../../hooks/useNotifications'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { VendorSettingsQuery } from '../../../queries/vendors.gql'
import { ContractSettingsQuery } from '../../../queries/contracts.gql'
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs'
import ContractPaymentSettingsContent from './ContractPaymentSettingsContent'
import { membershipRoles } from '../../settings/teamManagement/membershipRoles'
import { handleError } from '../../../utils/utils'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { camelCase, isEqual, snakeCase } from 'lodash'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import CustomerNotificationsContent from '../../../components/notificationSettingsContent/CustomerNotificationsContent'
import AsideContainer from '../../../components/containers/AsideContainer'

const isResetEnabled = false

const ContractSettings = ({ contractId, contractName }) => {
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()
  const location = useLocation()

  const tabs = [
    { name: 'paymentSettings', breadcrumbLabel: 'paymentSettings' },
    { name: 'notifications', breadcrumbLabel: 'notifications' },
  ]
  const currentTabPage = location.pathname.split('/')[4]
  const page = currentTabPage || snakeCase(tabs[0].name)
  const tabsMap = tabs.map((tab) => ({
    label: t(tab.name),
    active: camelCase(page) === tab.name,
    link: snakeCase(tab.name),
    breadcrumbLabel: t(tab.breadcrumbLabel),
  }))

  useEffect(() => {
    const breadcrumbs = [
      { label: t('customers'), link: '/customers' },
      { label: contractName, link: `/customers/${contractId}` },
      { label: t('settings') },
    ]

    if (currentTabPage) {
      breadcrumbs.push({
        label: t(tabsMap.find((tab) => isEqual(tab.link, currentTabPage))?.breadcrumbLabel),
      })
    } else {
      breadcrumbs.push({ label: t('allSettings') })
    }

    setBreadcrumbs(breadcrumbs)
  }, [page])

  const { newNotification } = useNotifications()
  const currentUser = useCurrentUser()
  const [isLoading, setIsLoading] = useState(false)
  const [paymentSettings, setPaymentSetting] = useState({})

  const { data: contractSettings, error: contractSettingsError } = useCustomQuery({
    query: ContractSettingsQuery,
    queryOptions: {
      variables: { id: contractId },
      skip: !contractId,
    },
    rollbarOptions: { operationName: 'ContractSettingsQuery', target: 'ContractSettings' },
  })

  const notificationInitialValues = contractSettings?.contract?.contractNotificationSettings

  const { data: vendorSettings, error: vendorSettingsError } = useCustomQuery({
    query: VendorSettingsQuery,
    queryOptions: {
      variables: { id: currentUser?.vendorId, withPaymentSettings: true },
      skip: !currentUser?.vendorId,
    },
    rollbarOptions: { operationName: 'VendorSettingsQuery', target: 'ContractSettings' },
  })
  const [updateContractPaymentSetting, { loading: isUpdateContractPaymentSettingLoading }] =
    useCustomMutation({
      mutation: UpdateContractPaymentSettingRequest,
      rollbarOptions: {
        operationName: 'UpdateContractPaymentSettingRequest',
        target: 'ContractSettings',
      },
      mutationOptions: {
        refetchQueries: [ContractSettingsQuery],
      },
    })

  const [deleteContractPaymentSetting, { loading: isDeleteContractPaymentSettingLoading }] =
    useCustomMutation({
      mutation: DeleteContractPaymentSettingRequest,
      rollbarOptions: {
        operationName: 'DeleteContractPaymentSettingRequest',
        target: 'ContractSettings',
      },
      mutationOptions: {
        refetchQueries: [ContractSettingsQuery],
      },
    })
  const [hasContractCustomSettings, setHasContractCustomSettings] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    if (contractSettings?.contract?.paymentSettings) {
      setHasContractCustomSettings(true)
      setPaymentSetting(contractSettings?.contract?.paymentSettings)
    }
    if (!contractSettings?.contract?.paymentSettings && !vendorSettings?.vendor?.paymentSettings) {
      setPaymentSetting({})
    }
    if (contractSettingsError || vendorSettingsError) {
      newNotification({ error: t('dataFetchFailed') })
      handleError(contractSettingsError || vendorSettingsError, newNotification)
    }
    setIsLoading(false)
  }, [contractSettings, vendorSettings])

  const updatePaymentSettings = (variables, closeFunc) => {
    updateContractPaymentSetting({
      variables: { ...variables },
    }).then(({ data }) => {
      const responseData = data?.createOrUpdateContractPaymentSetting || {}

      if (responseData?.entity) {
        newNotification({ success: t('paymentSettingsUpdated') })
        if (closeFunc) {
          closeFunc(false)
        }
      }
    })
  }

  const getBackToGeneralSettings = () => {
    const variables = {
      id: contractId,
    }
    deleteContractPaymentSetting({
      variables,
    }).then(({ data }) => {
      const responseData = data?.deleteContractPaymentSetting || {}

      if (responseData?.entity) {
        setHasContractCustomSettings(false)
        newNotification({ success: t('paymentSettingWereSetToGeneral') })
      }
    })
  }

  return (
    <div className={'mb-10'}>
      <PageHeader headerTitle={t('settings')} />
      <Tabs tabs={tabsMap} />

      {isResetEnabled && hasContractCustomSettings && (
        <Flex className={'pb-8'} justifyContent={'end'}>
          <Button
            className={'mr-2'}
            disabled={
              currentUser?.membershipRole !== membershipRoles.ADMIN ||
              isDeleteContractPaymentSettingLoading ||
              isUpdateContractPaymentSettingLoading
            }
            iconName={'refresh'}
            iconType={'outline'}
            label={t('backToGeneralSettings')}
            onClick={getBackToGeneralSettings}
            testData="back-general-settings"
            variant={'secondary'}
          />
        </Flex>
      )}

      <Section className="mt-6">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Routes>
            <Route element={<Navigate to="payment_settings" replace />} path="/" />
            <Route
              element={
                <ContractPaymentSettingsContent
                  data={paymentSettings}
                  disabledOptions={['financeCharges']}
                  isReadOnly={currentUser?.membershipRole !== membershipRoles.ADMIN}
                  isUpdatePaymentSettingsLoading={
                    isUpdateContractPaymentSettingLoading || isDeleteContractPaymentSettingLoading
                  }
                  loading={isLoading}
                  updatePaymentSettings={updatePaymentSettings}
                  withPaymentTerms={vendorSettings?.vendor?.paymentSettings?.showPaymentTerms}
                />
              }
              path="/payment_settings"
            />
            <Route
              element={
                <AsideContainer
                  title={t('customerNotifications')}
                  titleChild={
                    <Flex column>
                      <Text className="pb-2" color="text-warmBlack-400">
                        {t('manageContractNotifications1')}
                      </Text>
                      <Text className="pb-2" color="text-warmBlack-400">
                        {t('manageContractNotifications2')}
                      </Text>
                    </Flex>
                  }>
                  <CustomerNotificationsContent
                    handleSubmit={() => false}
                    initialValues={notificationInitialValues}
                    isReadOnly={currentUser?.membershipRole !== membershipRoles.ADMIN}
                    loading={false}
                    refetchQueries={ContractSettingsQuery}
                    requestInProgress={false}
                  />
                </AsideContainer>
              }
              path="/notifications"
            />
          </Routes>
        )}
      </Section>
    </div>
  )
}

export default ContractSettings

ContractSettings.propTypes = {
  contractId: PT.number.isRequired,
  contractName: PT.string.isRequired,
}
