import { makeOptions } from '../../../../utils/utils'
import { range } from 'lodash'
import { DateTime } from 'luxon'

export const getAutopayOptions = (t) => {
  const options = [
    {
      label: t('payWhenDue'),
      value: 'as_due',
      name: 'as_due',
    },
  ].concat(makeOptions(range(1, 29, 1)))

  options.push({
    label: t('lastDayOfMonth'),
    value: 'last_day_of_month',
    name: 'last_day_of_month',
  })
  return options
}
const AUTOPAY_TIME = 17
const getShiftedAtopayDateTime = (dateTime, vendorTimeZone) => {
  const { day, month, year } = dateTime
  return DateTime.fromObject({ year, month, day, hour: AUTOPAY_TIME }, { zone: vendorTimeZone })
}
export const getNextAutopayDate = (dayOfPayment, vendorTimeZone = 'America/New_York') => {
  const current = DateTime.now()
  const { day, month, year } = current
  if (dayOfPayment === 'last_day_of_month') {
    const newDate = getShiftedAtopayDateTime(
      DateTime.fromObject({ year, month, day }).endOf('month'),
      vendorTimeZone,
    )
    if (newDate.toUnixInteger() > current.toUnixInteger()) {
      return newDate.toISO()
    } else {
      return getShiftedAtopayDateTime(
        DateTime.fromObject({ year, month, day: dayOfPayment }).plus({ months: 1 }).endOf('month'),
        vendorTimeZone,
      ).toISO()
    }
  } else {
    const newDate = getShiftedAtopayDateTime(
      DateTime.fromObject({ year, month, day: dayOfPayment }),
      vendorTimeZone,
    )
    if (newDate.toUnixInteger() > current.toUnixInteger()) {
      return newDate.toISO()
    } else {
      return getShiftedAtopayDateTime(newDate.plus({ months: 1 }), vendorTimeZone).toISO()
    }
  }
}

export const convertTimeZone = (vendorTimeZone) => {
  const current = DateTime.now()
  const { day, month, year } = current
  return DateTime.fromObject({ year, month, day }, { zone: vendorTimeZone }).toFormat('ZZZZ')
}
