import { useQuery } from '@apollo/client'
import { useRollbar } from '@rollbar/react'
import { captureCritical } from '../utils/rollbar'

const useCustomQuery = ({ query, queryOptions = {}, onCompleted, rollbarOptions = {} }) => {
  const rollbar = useRollbar()

  const { data, loading, error, refetch } = useQuery(query, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    ...queryOptions,
    context: {
      rollbar,
      rollbarOptions,
    },
    onCompleted: (response) => {
      if (!response) {
        return
      }

      try {
        onCompleted?.(response)
      } catch (error) {
        captureCritical(rollbar, error, { target: 'useCustomQuery', ...rollbarOptions })
      }
    },
  })

  return { data, loading, error, refetch }
}

export { useCustomQuery }
