import React, { useCallback, useEffect, useState } from 'react'
import { Box, Flex, Icon, Dialog, ConfirmationDialog, Text } from '../../../ui-kit'
import PT from 'prop-types'
import { ACTIVITY_TYPES } from '../../../constants/activities'
import { formatCustomerActivity } from '../../../utils/formatActivities'
import P2pActivity from './P2pActivity'
import PaymentPlanActivity from './PaymentPlanActivity'
import DisputeActivity from './DisputeActivity'
import PaymentActivity from './PaymentActivity'
import PaymentContent from '../../payments/paymentsTab/PaymentContent'
import PaymentPlanContent from '../../payments/paymentPlan/PaymentPlanContent'
import { CancelPaymentPlan } from '../../../queries/mutations/cancelPaymentPlanRequest.gql'
import { ContractEventsQuery } from '../../../queries/contractEvents.gql'
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../../../hooks/useNotifications'
import DisputeContent from './DisputeContent'
import PaymentRequestActivity from './PaymentRequestActivity'
import PromiseToPayContent from '../../payments/promiseToPay/PromiseToPayContent'
import NoteActivity from './NoteActivity'
import PaymentRequestContent from './PaymentRequestContent'
import activityType from '../../../types/activities'
import { camelCase } from 'lodash'
import { eventActions, paymentTransactionStatuses } from '../../payments/paymentsTab/util'
import AddNoteForm from '../addNoteForm/AddNoteForm'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import SidebarDepositContent from '../../invoices/sidebar/SidebarDepositContent'
import { CancelDeposit } from '../../../queries/mutations/cancelDeposit.gql'
import { InvoicesDepositsQuery } from '../../../queries/invoices/invoicesDeposits.gql'
import DepositActivity from './DepositActivity'
import DepositReturnModal from '../../invoices/depositReturn/DepositReturnModal'
import RefundContent from '../../payments/refunds/RefundContent'
import RefundActivity from './RefundActivity'
import UpdatePromiseToPayModal from '../../payments/promiseToPay/UpdatePromiseToPayModal'
import { CancelPaymentPromise } from '../../../queries/mutations/cancelPaymentPromiseRequest.gql'
import { PaymentPromisesQuery } from '../../../queries/paymentPromises.gql'
import { getFormattedDate } from '../../../ui-kit/utils/dateUtils'
import { DeleteNote } from '../../../queries/mutations/deleteNote.gql'
import { ContractNotes } from '../../../queries/contractNotes.gql'
import { VoidPaymentTransactionRequest } from '../../../queries/mutations/voidPaymentTransaction.gql'
import { CapturePaymentTransactionRequest } from '../../../queries/mutations/capturePaymentTransaction.gql'
import { paymentMethodTypes, paymentResultMods } from '../../../constants/paymentResults'
import { DepositQuery } from '../../../queries/invoices/invoicesDeposits.gql'
import PaymentResult from '../../../components/paymentResult/PaymentResult'
import CreditReviewActivity from './CreditReviewActivity'
import CreditReviewContent from '../customerInfo/creditReview/CreditReviewContent'
import CreditReviewDocumentUploadModal from '../customerInfo/creditReview/CreditReviewDocumentUploadModal'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { handleError } from '../../../utils/utils'
import VoidErrorMessageDialog from '../../payments/VoidErrorMessageDialog'
import colors from '../../../ui-kit/colors'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'

const ContractActivity = ({
  activity,
  className,
  setSidebarIsOpened,
  setSidebarContent,
  refetch,
  contractId,
}) => {
  const [failureReason, setFailureReason] = useState(null)
  const [isVoidErrorModalOpened, setIsVoidErrorModalOpened] = useState(false)
  const [voidErrorCode, setVoidErrorCode] = useState()
  const [isVoidModalOpened, setIsVoidModalOpened] = useState(false)

  const handleOpenVoidModal = () => {
    setSidebarIsOpened(false)
    setIsVoidModalOpened(true)
  }

  const customErrorHandling = useCallback((errors) => {
    if (errors.length) {
      const error = errors[0]
      const errorCode = error?.extensions?.errorCode
      if (errorCode) {
        setIsVoidErrorModalOpened(true)
        setVoidErrorCode(errorCode)
      } else {
        handleError(errors, newNotification)
      }
    }
  }, [])

  const handleCancelVoidPayment = useCallback(() => {
    setSidebarIsOpened(true)
    setIsVoidModalOpened(false)
  }, [setSidebarIsOpened, setIsVoidModalOpened])

  const handleVoid = () => {
    const variables = { id: activity.entity?.paymentTransaction?.id }
    voidPaymentTransaction({
      variables,
    }).then(({ data }) => {
      const responseData = data?.voidPaymentTransaction || {}

      if (responseData?.entity) {
        newNotification({ success: t('transactionHasBeenVoided') })
      }

      setIsVoidModalOpened(false)
    })
  }

  const renderActivityContent = (activity) => {
    switch (activity.eventEntity) {
      case ACTIVITY_TYPES.PAYMENT_PROMISE:
        return <P2pActivity p2p={activity} />
      case ACTIVITY_TYPES.PAYMENT_PLAN:
        return (
          <PaymentPlanActivity
            paymentPlan={{ ...activity, ...activity.entity, id: activity.entity.id }}
          />
        )
      case ACTIVITY_TYPES.DISPUTE:
        return (
          <DisputeActivity dispute={{ ...activity, ...activity.entity, id: activity.entity.id }} />
        )
      case ACTIVITY_TYPES.PAYMENT:
        return (
          <PaymentActivity payment={{ ...activity, ...activity.entity, id: activity.entity.id }} />
        )
      case ACTIVITY_TYPES.PAYMENT_REQUEST:
        return (
          <PaymentRequestActivity
            paymentRequest={{ ...activity, ...activity.entity, id: activity.entity.id }}
          />
        )
      case ACTIVITY_TYPES.NOTE:
        return <NoteActivity note={{ ...activity, ...activity.entity, id: activity.entity.id }} />
      case ACTIVITY_TYPES.DEPOSIT:
        return (
          <DepositActivity deposit={{ ...activity, ...activity.entity, id: activity.entity.id }} />
        )
      case ACTIVITY_TYPES.REFUND:
        return (
          <RefundActivity refund={{ ...activity, ...activity.entity, id: activity.entity.id }} />
        )
      case ACTIVITY_TYPES.CREDIT_REVIEW:
        return (
          <CreditReviewActivity
            creditReview={{ ...activity, ...activity.entity, id: activity.entity.id }}
          />
        )
    }
  }
  const [cancelPaymentPlan, { loading: isCancelPaymentPlanLoading }] = useCustomMutation({
    mutation: CancelPaymentPlan,
    rollbarOptions: { operationName: 'CancelPaymentPlan', target: 'ContractActivity' },
    mutationOptions: {
      refetchQueries: [ContractEventsQuery],
    },
  })
  const { t } = useTranslation()
  const { newNotification } = useNotifications()
  const [isOpenedPaymentPlanCancelModal, setIsOpenedPaymentPlanCancelModal] = useState(false)
  const [isOpenedP2PCancelModal, setIsOpenedP2PCancelModal] = useState(false)
  const [isOpenedCancelRequestConfirmationModal, setIsOpenedCancelRequestConfirmationModal] =
    useState(false)
  const [isOpenedDeleteNoteConfirmationModal, setIsOpenedDeleteNoteConfirmationModal] =
    useState(false)
  const [
    isOpenedCancelAuthorizationConfirmationModal,
    setIsOpenedCancelAuthorizationConfirmationModal,
  ] = useState(false)
  const [isAddCreditReviewDocumentModalOpened, setIsAddCreditReviewDocumentModalOpened] =
    useState(false)

  const handleCancelPaymentPlan = () => {
    cancelPaymentPlan({
      variables: { id: activity.entity.id },
      refetchQueries: [ContractEventsQuery],
    }).then(({ data }) => {
      const responseData = data?.cancelPaymentPlan || {}

      if (responseData?.entity) {
        newNotification({ success: t('paymentPlanCanceled') })
      }

      setSidebarIsOpened(false)
    })
  }
  const [cancelDeposit, { loading: isCancelDepositLoading }] = useCustomMutation({
    mutation: CancelDeposit,
    rollbarOptions: { operationName: 'CancelDeposit', target: 'ContractActivity' },
    mutationOptions: {
      refetchQueries: [InvoicesDepositsQuery, ContractEventsQuery],
    },
  })

  const [p2pFormDirty, setP2pFormDirty] = useState(false)
  const [selectedPromise, setSelectedPromise] = useState({})
  const [isUpdatePromiseModalOpened, setIsUpdatePromiseModalOpened] = useState(false)
  const reopenSidebar = useCallback(() => {
    setSidebarIsOpened(true)
  }, [setSidebarIsOpened])
  const [isReturnDepositModalOpened, setIsReturnDepositModalOpened] = useState(false)

  const handleUpdatePromiseModal = (p2p) => {
    setSelectedPromise(p2p)
    setIsUpdatePromiseModalOpened(true)
  }

  const [cancelPaymentPromise, { loading: isCancelPaymentPromiseLoading }] = useCustomMutation({
    mutation: CancelPaymentPromise,
    rollbarOptions: { operationName: 'CancelPaymentPromise', target: 'ContractActivity' },
    mutationOptions: {
      refetchQueries: [PaymentPromisesQuery, ContractEventsQuery],
    },
  })
  const handleCancelPaymentPromise = useCallback(() => {
    cancelPaymentPromise({
      variables: { id: activity.entity.paymentPromiseId },
    }).then(({ data }) => {
      const responseData = data?.cancelPaymentPromise || {}

      if (responseData?.entity) {
        newNotification({ success: t('paymentPromiseCanceled') })
        closeForm()
        setIsOpenedP2PCancelModal(false)
      }
    })
  }, [])
  const cancelRequestHandler = useCallback(() => {
    const variables = { id: activity.entity.depositId }
    cancelDeposit({ variables }).then(({ data }) => {
      const responseData = data?.cancelDeposit || {}

      if (responseData?.entity) {
        newNotification({ success: t('requestCancelledSuccessfully') })
        setIsOpenedCancelRequestConfirmationModal(false)
      }
    })
  }, [cancelDeposit])

  const [voidPaymentTransaction, { loading: voidPaymentTransactionLoading }] = useCustomMutation({
    mutation: VoidPaymentTransactionRequest,
    rollbarOptions: { operationName: 'VoidPaymentTransactionRequest', target: 'ContractActivity' },
    mutationOptions: {
      refetchQueries: [ContractEventsQuery],
    },
    customErrorHandling,
  })
  const cancelAuthorizationHandler = useCallback(() => {
    const variables = { id: activity.entity?.latestPaymentTransaction?.id }
    voidPaymentTransaction({ variables }).then(({ data }) => {
      const responseData = data?.voidPaymentTransaction || {}

      if (responseData?.entity) {
        newNotification({ success: t('depositAuthorizationHasBeenCancelled') })
        setIsOpenedCancelAuthorizationConfirmationModal(false)
      }
    })
  }, [voidPaymentTransaction])
  const [capturePaymentTransaction, { loading: isCapturePaymentTransactionLoading }] =
    useCustomMutation({
      mutation: CapturePaymentTransactionRequest,
      rollbarOptions: {
        operationName: 'CapturePaymentTransactionRequest',
        target: 'ContractActivity',
      },
    })
  const [paymentResult, setPaymentResult] = useState(null)
  const [authorizedDepositId, setAuthorizedDepositId] = useState(null)
  const [paymentStatusRefreshCounter, setPaymentStatusRefreshCounter] = useState(0)
  const runAuthorizedPaymentHandler = useCallback(() => {
    setSidebarIsOpened(false)
    setPaymentResult(paymentResultMods.LOADING)
    const variables = { id: activity.entity?.latestPaymentTransaction?.id }
    capturePaymentTransaction({ variables }).then(({ data, errors }) => {
      const responseData = data?.capturePaymentTransaction || {}

      if (responseData?.entity) {
        const paymentMethodType = responseData.entity.paymentMethod?.type

        if (paymentMethodType === paymentMethodTypes.ACH_PAYMENT_METHOD) {
          setPaymentResult(paymentResultMods.ACH_SUCCESS)
        } else if (paymentMethodType === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD) {
          setPaymentResult(paymentResultMods.CREDIT_CARD_SUCCESS)
        } else {
          setAuthorizedDepositId(activity.entity.hashid)
          setPaymentStatusRefreshCounter((prevState) => prevState + 1)
        }
      } else if (responseData?.errors?.length || errors) {
        setPaymentResult(paymentResultMods.SERVER_ERROR)
      }
    })
  }, [])
  const handleDoneClick = useCallback(() => {
    refetch()
    setAuthorizedDepositId(null)
    setPaymentResult(null)
    setPaymentStatusRefreshCounter(0)
  }, [])
  const { refetch: refetchDeposit } = useCustomQuery({
    query: DepositQuery,
    queryOptions: {
      variables: { id: authorizedDepositId },
      skip: !authorizedDepositId,
    },
    rollbarOptions: { operationName: 'DepositQuery', target: 'ContractActivity' },
  })
  useEffect(async () => {
    if (paymentStatusRefreshCounter === 0) {
      return
    }

    const { data: loadedData } = await refetchDeposit({ id: authorizedDepositId })

    const loadedDeposit = loadedData?.deposit

    if (
      !loadedDeposit?.latestPaymentTransaction ||
      loadedDeposit.latestPaymentTransaction.paymentMethod?.type ===
        paymentMethodTypes.ACH_PAYMENT_METHOD
    ) {
      return
    }

    let transactionPaymentResult = null

    if (loadedDeposit.latestPaymentTransaction.status === paymentTransactionStatuses.PAID) {
      transactionPaymentResult = paymentResultMods.CREDIT_CARD_SUCCESS
    } else if (
      loadedDeposit.latestPaymentTransaction.status === paymentTransactionStatuses.FAILED ||
      loadedDeposit.latestPaymentTransaction.status === paymentTransactionStatuses.CANCELLED
    ) {
      transactionPaymentResult = paymentResultMods.CREDIT_CARD_FAILURE
      setFailureReason(loadedDeposit.latestPaymentTransaction.failureReason)
    }

    if (transactionPaymentResult) {
      setPaymentResult(transactionPaymentResult)

      return
    }

    const timeoutId = setTimeout(() => {
      setPaymentStatusRefreshCounter((prevState) => prevState + 1)
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [paymentStatusRefreshCounter, refetchDeposit, authorizedDepositId])

  const renderSidebarContent = (activity) => {
    switch (activity.eventEntity) {
      case ACTIVITY_TYPES.PAYMENT_PROMISE:
        return (
          <PromiseToPayContent
            data={{
              ...activity,
              ...activity.entity,
              id: activity.entity.paymentPromiseId,
              status: activity.entity.paymentPromiseStatus,
            }}
            isFormDirty={p2pFormDirty}
            setDirtyFormState={setP2pFormDirty}
            setIsOpenedCancelModal={setIsOpenedP2PCancelModal}
            setIsSidebarOpened={setSidebarIsOpened}
            setIsUpdatePromiseModalOpened={() => handleUpdatePromiseModal(activity.entity)}
          />
        )
      case ACTIVITY_TYPES.PAYMENT_PLAN:
        return (
          <PaymentPlanContent
            data={{ ...activity, ...activity.entity, id: activity.entity.id }}
            setIsOpenedCancelModal={setIsOpenedPaymentPlanCancelModal}
            setIsSidebarOpened={setSidebarIsOpened}
          />
        )
      case ACTIVITY_TYPES.DISPUTE:
        return (
          <DisputeContent
            data={{ ...activity, ...activity.entity, id: activity.entity.disputeId }}
            setSidebarIsOpened={setSidebarIsOpened}
          />
        )
      case ACTIVITY_TYPES.PAYMENT:
        return (
          <PaymentContent
            contractId={contractId}
            id={activity.entity?.paymentTransaction?.id}
            paymentMethod={activity.entity?.paymentMethod?.title}
            paymentMethodNickname={activity.entity?.paymentMethod?.nickname}
            transaction={activity.entity.paymentTransaction}
            voidTransaction={handleOpenVoidModal}
          />
        )
      case ACTIVITY_TYPES.PAYMENT_REQUEST:
        return (
          <PaymentRequestContent
            data={{ ...activity, ...activity.entity, id: activity.entity.id }}
          />
        )
      case ACTIVITY_TYPES.DEPOSIT:
        return (
          <>
            <SidebarDepositContent
              data={{ ...activity, ...activity.entity, id: activity.entity.depositId }}
              isRunAuthorizedPaymentDisabled={isCapturePaymentTransactionLoading}
              runAuthorizedPaymentHandler={runAuthorizedPaymentHandler}
              setIsOpenedCancelAuthorizationConfirmationModal={
                setIsOpenedCancelAuthorizationConfirmationModal
              }
              setIsOpenedCancelRequestConfirmationModal={setIsOpenedCancelRequestConfirmationModal}
              setIsReturnDepositModalOpened={setIsReturnDepositModalOpened}
              setIsSidebarOpened={setSidebarIsOpened}
            />
          </>
        )
      case ACTIVITY_TYPES.REFUND:
        return <RefundContent data={{ ...activity, ...activity.entity, id: activity.entity.id }} />
      case ACTIVITY_TYPES.CREDIT_REVIEW:
        return (
          <CreditReviewContent
            data={{ ...activity, ...activity.entity, id: activity.entity.id }}
            setIsAddCreditReviewDocumentModalOpened={setIsAddCreditReviewDocumentModalOpened}
            setIsSidebarOpened={setSidebarIsOpened}
          />
        )
    }
  }
  const formattedActivity = formatCustomerActivity(activity, t)
  if (
    activity.eventEntity === ACTIVITY_TYPES.PAYMENT &&
    camelCase(activity.entityActualEvent?.eventAction) === eventActions.IN_PROCESS
  ) {
    return null
  }
  const [selectedNote, setSelectedNote] = useState({})
  const [isViewNoteModalOpened, setIsViewNoteModalOpened] = useState(false)
  const onCompletedDeleteNote = useCallback(() => {
    setIsOpenedDeleteNoteConfirmationModal(false)
    setSelectedNote({})
  }, [])
  const [deleteNote, { loading: isDeleteNoteLoading }] = useCustomMutation({
    mutation: DeleteNote,
    onCompleted: onCompletedDeleteNote,
    rollbarOptions: { operationName: 'DeleteNote', target: 'ContractActivity' },
    mutationOptions: {
      refetchQueries: [ContractEventsQuery, ContractNotes],
    },
  })
  const handleDeleteNote = useCallback(() => {
    deleteNote({
      variables: {
        id: selectedNote.id || selectedNote.noteId,
      },
    })
  }, [selectedNote])

  const handleNoteClick = useCallback((note) => {
    setSelectedNote(note)
    setIsViewNoteModalOpened(true)
  }, [])
  const closeForm = useCallback(() => {
    setIsViewNoteModalOpened(false)
    setSelectedNote({})
  }, [setIsViewNoteModalOpened])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  const handleActivityClick = () => {
    if (activity.eventEntity === ACTIVITY_TYPES.NOTE) {
      handleNoteClick(activity.entity)
    } else {
      setSidebarContent(renderSidebarContent(activity))
      setSidebarIsOpened(true)
    }
  }

  const handleCloseCancelPaymentPlanModal = useCallback(() => {
    setIsOpenedPaymentPlanCancelModal(false)
    setSidebarIsOpened(true)
  }, [setIsOpenedPaymentPlanCancelModal, setSidebarIsOpened])
  const handleCloseCancelPaymentPromiseModal = useCallback(() => {
    setIsOpenedP2PCancelModal(false)
    setSidebarIsOpened(true)
  }, [setIsOpenedP2PCancelModal, setSidebarIsOpened])
  const handleCloseCancelDepositRequestModal = useCallback(() => {
    setIsOpenedCancelRequestConfirmationModal(false)
    setSidebarIsOpened(true)
  }, [setIsOpenedCancelRequestConfirmationModal, setSidebarIsOpened])
  const handleCloseDeleteNoteModal = useCallback(() => {
    setIsOpenedDeleteNoteConfirmationModal(false)
    setIsViewNoteModalOpened(true)
  }, [setIsOpenedDeleteNoteConfirmationModal, setIsViewNoteModalOpened])
  const handleCloseCancelDepositAuthorizationModal = useCallback(() => {
    setIsOpenedCancelAuthorizationConfirmationModal(false)
    setSidebarIsOpened(true)
  }, [setIsOpenedCancelAuthorizationConfirmationModal, setSidebarIsOpened])

  return formattedActivity ? (
    <>
      <Flex alignItems={'center'} className={`w-full ${className}`}>
        <Flex
          alignItems={'center'}
          className={'bg-white rounded-full h-[60px] w-[60px]'}
          justifyContent={'center'}
          shrink={0}>
          <Flex
            alignItems={'center'}
            className={`flex justify-center items-center rounded-full overflow-hidden 
          ${formattedActivity.bgColorName} h-14 w-14 border ${formattedActivity.borderColorName}`}
            justifyContent={'center'}
            shrink={0}>
            {formattedActivity.iconName ? (
              <Icon
                className={'w-6 h-6'}
                color={formattedActivity.iconColor}
                name={formattedActivity.iconName}
                type={'outline'}
              />
            ) : (
              formattedActivity.iconElement
            )}
          </Flex>
        </Flex>
        <Box className={'ml-6 w-full'} id={activity.id} onClick={handleActivityClick}>
          {renderActivityContent(formattedActivity)}
        </Box>
      </Flex>
      <Dialog
        isOpened={isViewNoteModalOpened}
        setIsOpened={requestClose}
        title={`${t('note')} - ${getFormattedDate(selectedNote?.createdAt || '')}`}>
        <AddNoteForm
          closeForm={closeForm}
          handleDeleteNote={() => {
            setIsViewNoteModalOpened(false)
            setIsOpenedDeleteNoteConfirmationModal(true)
          }}
          isFormDirty={isFormDirty}
          note={selectedNote}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          setIsOpenedModal={setIsViewNoteModalOpened}
          isViewMode
        />
      </Dialog>
      <DepositReturnModal
        data={{ ...activity, ...activity.entity, id: activity.entity.id }}
        isOpened={isReturnDepositModalOpened}
        reopenSidebar={reopenSidebar}
        setIsOpened={setIsReturnDepositModalOpened}
      />
      {isUpdatePromiseModalOpened && (
        <UpdatePromiseToPayModal
          data={selectedPromise}
          isOpened={isUpdatePromiseModalOpened}
          refetchQuery={refetch}
          setIsOpenedModal={setIsUpdatePromiseModalOpened}
          setIsSidebarOpened={setSidebarIsOpened}
          setSelectedPromise={setSelectedPromise}
        />
      )}

      <ConfirmationDialog
        confirmationMessage={t('deletePaymentPlanWarning')}
        isOpened={isOpenedPaymentPlanCancelModal}
        isSubmitButtonYesDisabled={isCancelPaymentPlanLoading}
        onModalClose={() => setSidebarIsOpened(true)}
        onSubmitButtonNoClick={handleCloseCancelPaymentPlanModal}
        onSubmitButtonYesClick={handleCancelPaymentPlan}
        setIsOpened={setIsOpenedPaymentPlanCancelModal}
        title={t('cancelPaymentPlan')}
      />

      <ConfirmationDialog
        confirmationMessage={t('cancelP2PConfirmationWarn')}
        isOpened={isOpenedP2PCancelModal}
        isSubmitButtonYesDisabled={isCancelPaymentPromiseLoading}
        onModalClose={() => setSidebarIsOpened(true)}
        onSubmitButtonNoClick={handleCloseCancelPaymentPromiseModal}
        onSubmitButtonYesClick={handleCancelPaymentPromise}
        setIsOpened={setIsOpenedP2PCancelModal}
        title={t('cancelPromiseToPay')}
      />

      <ConfirmationDialog
        confirmationMessage={t('cancelDepositRequestConfirmationModalMessage')}
        isOpened={isOpenedCancelRequestConfirmationModal}
        isSubmitButtonYesDisabled={isCancelDepositLoading}
        onModalClose={() => setSidebarIsOpened(true)}
        onSubmitButtonNoClick={handleCloseCancelDepositRequestModal}
        onSubmitButtonYesClick={cancelRequestHandler}
        setIsOpened={setIsOpenedCancelRequestConfirmationModal}
        title={t('cancelDepositRequest')}
      />

      <ConfirmationDialog
        confirmationMessage={t('cancelDepositAuthorizationModalMessage')}
        isOpened={isOpenedCancelAuthorizationConfirmationModal}
        isSubmitButtonYesDisabled={voidPaymentTransactionLoading}
        onModalClose={() => setSidebarIsOpened(true)}
        onSubmitButtonNoClick={handleCloseCancelDepositAuthorizationModal}
        onSubmitButtonYesClick={cancelAuthorizationHandler}
        setIsOpened={setIsOpenedCancelAuthorizationConfirmationModal}
        title={t('cancelAuthorization')}
      />

      <ConfirmationDialog
        confirmationMessage={t('deleteNoteWarning')}
        isOpened={isOpenedDeleteNoteConfirmationModal}
        isSubmitButtonYesDisabled={isDeleteNoteLoading}
        onModalClose={() => setIsViewNoteModalOpened(true)}
        onSubmitButtonNoClick={handleCloseDeleteNoteModal}
        onSubmitButtonYesClick={handleDeleteNote}
        setIsOpened={setIsOpenedDeleteNoteConfirmationModal}
        title={t('deleteNote')}
      />

      <CreditReviewDocumentUploadModal
        creditReviewId={activity?.entity?.id}
        modalOpened={isAddCreditReviewDocumentModalOpened}
        onModalClose={() => {
          setSidebarIsOpened(true)
          setIsAddCreditReviewDocumentModalOpened(false)
        }}
      />

      {paymentResult && (
        <Dialog shouldCloseOnBackdropClick={false} hideCross isOpened>
          <PaymentResult
            failureReason={failureReason}
            mode={paymentResult}
            onDoneClick={handleDoneClick}
            setMode={setPaymentResult}
          />
        </Dialog>
      )}
      <ConfirmationDialog
        confirmationMessage={
          <Flex column>
            {t('voidWarningMsg')}
            <Text
              className="pt-4"
              color={colors.GRAY_700}
              fontWeight={fontWeight.MEDIUM}
              size={sizes.SM}>
              {t('voidsCannotBeUndone')}
            </Text>
          </Flex>
        }
        isOpened={isVoidModalOpened}
        isSubmitButtonYesDisabled={voidPaymentTransactionLoading}
        onModalClose={() => setSidebarIsOpened(true)}
        onSubmitButtonNoClick={handleCancelVoidPayment}
        onSubmitButtonYesClick={handleVoid}
        setIsOpened={setIsVoidModalOpened}
        title={t('voidPayment')}
      />
      <VoidErrorMessageDialog
        isVoidModalOpened={isVoidErrorModalOpened}
        onModalClose={() => setIsVoidErrorModalOpened(false)}
        setIsVoidModalOpened={setIsVoidErrorModalOpened}
        voidErrorCode={voidErrorCode}
      />
    </>
  ) : null
}

export default ContractActivity

ContractActivity.propTypes = {
  activity: activityType,
  className: PT.string,
  contractId: PT.string,
  setSidebarIsOpened: PT.func.isRequired,
  setSidebarContent: PT.func.isRequired,
  refetch: PT.func,
}
