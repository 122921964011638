import { MONEY } from '../../utils/dataTypes'
import { Money } from '../../ui-kit'

export const getDisplayStatusValue = (overdueLevels) =>
  overdueLevels
    ? overdueLevels.overdueTo === 0
      ? `${overdueLevels.overdueFrom - 1}+`
      : `${overdueLevels.overdueFrom}-${overdueLevels.overdueTo}`
    : 'current'

export const daysPastDueStatusMapping = {
  ['Current']: { label: 'current', color: 'green' },
  ['1-30 Days Past Due']: { label: '1-30', color: 'yellow' },
  ['31-60 Days Past Due']: { label: '31-60', color: 'orange' },
  ['61-90 Days Past Due']: { label: '61-90', color: 'red' },
  ['90+ Days Past Due']: { label: '90+', color: 'darkRed' },
}
export const statisticMapping = {
  '0-30': 'pastDue030Count',
  '31-60': 'pastDue3160Count',
  '61-90': 'pastDue6190Count',
  '91-0': 'pastDue90Count',
}

export const getStatisticData = (overdueLevelGroups = [], t) => {
  return overdueLevelGroups?.map((level) => {
    return {
      id: level.id,
      title: level.overdueLevel?.title || t('current'),
      value: level.contractsCount || 0,
      valueLabel: 'customers',
      level,
    }
  })
}

export const getStatisticInvoiceData = (overdueLevelGroups = [], t) =>
  overdueLevelGroups?.map((level) => ({
    id: level.id,
    title: level.overdueLevel?.title || t('current'),
    value: level.outstandingAmountCents || 0,
    type: MONEY,
    level,
    secondaryValue: level.retentionAmountCents ? (
      <Money value={level.retentionAmountCents} />
    ) : null,
    secondaryLabel: level.retentionAmountCents ? t('retention') : null,
  }))

export const paymentPlanStatuses = {
  INITIAL: 'initial',
  PARTIALLY_PAID: 'partially_paid',
  PAID: 'paid',
  CANCELLED: 'cancelled',
}

export const statusPaymentPlanMapping = {
  [paymentPlanStatuses.INITIAL]: { label: 'active', color: 'green' },
  [paymentPlanStatuses.PARTIALLY_PAID]: { label: 'active', color: 'green' },
  [paymentPlanStatuses.PAID]: { label: 'completed', color: 'green' },
  [paymentPlanStatuses.CANCELLED]: { label: 'cancelled', color: 'red' },
}
export const DEPOSIT = 'Deposit'
export const PAYMENT = 'Payment'

export const paymentTypeMapping = {
  [DEPOSIT]: 'deposit',
  [PAYMENT]: 'invoice',
}

export const getCreatedByEvent = (entity) => {
  return entity?.createdBy?.fullName || (entity?.createdBySystem ? 'System' : '')
}
