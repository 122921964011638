import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import { Button, Flex, InfoNote, Money, Text, Tooltip } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import React, { useState } from 'react'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import {
  displayStatusesMapping,
  getRecipientsViaEmail,
  paymentTransactionDisplayStatuses,
} from './util'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { paymentMethodTypes } from '../../../constants/paymentResults'
import MemoField from '../../../ui-kit/components/inputs/MemoField'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { UpdatePaymentTransactionMemo } from '../../../queries/mutations/updatePaymentMemo.gql'
import { PaymentTransactionsQuery } from '../../../queries/paymentTransactions.gql'
import { useNavigate } from 'react-router-dom'
import ResendPaymentReceiptForm from './ResendPaymentReceiptForm'
import { ResendReceiptMutation } from '../../../queries/mutations/resendReceipt.gql'
import { useNotifications } from '../../../hooks/useNotifications'

const DepositPaymentContent = ({ transaction, id, voidTransaction, isAuthorization }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const data = transaction.payable
  const paymentMethod = transaction.paymentMethod?.title
    ? `${transaction.paymentMethod?.title}${
        transaction.paymentMethod?.nickname ? ` (${transaction.paymentMethod?.nickname})` : ''
      }`
    : '-'
  const feeAmountCents = transaction.feeAmountCents
  const status = transaction.displayStatus
  const [memoFormIsDirty, setMemoFormIsDirty] = useState(false)
  const [isResendPaymentModalOpened, setIsResendPaymentModalOpened] = useState(false)
  const buyerId = data?.contract?.buyer?.id
  const buyerName = data?.contract?.buyer?.name
  const [updatePaymentMemo, { loading }] = useCustomMutation({
    mutation: UpdatePaymentTransactionMemo,
    rollbarOptions: { operationName: 'UpdatePaymentTransactionMemo', target: 'PaymentContent' },
    mutationOptions: {
      refetchQueries: [PaymentTransactionsQuery],
    },
  })
  const descriptionData = [
    {
      label: t('status'),
      value: (
        <StatusBadge
          color={displayStatusesMapping[status]?.color}
          value={t(displayStatusesMapping[status]?.label)}
        />
      ),
    },
    {
      label: t('details'),
      value: (
        <Tooltip
          className="text-ellipsis overflow-hidden max-w-72 whitespace-nowrap text-sm"
          content={transaction.failureReason}>
          {transaction.failureReason}
        </Tooltip>
      ),
      hidden: !transaction.failureReason,
    },
    { label: t('erpID'), value: data?.erpId || '-' },
    { label: t('type'), value: data?.overpayment ? t('overpayment') : t('deposit') },
    { label: t('source'), value: t(data?.source) },
    {
      label: t('paymentMethod'),
      value: paymentMethod,
    },
    {
      label: t('depositedTo'),
      value: data?.financialEntity?.name || '-',
    },
    {
      label: t('paymentDate'),
      value: <FormattedDate date={transaction?.entryDate} format={'MM/dd/yyyy'} />,
      hidden: isAuthorization,
    },
    {
      label: t('createdOn'),
      value: <FormattedDate date={transaction?.processedAt} format={'MM/dd/yyyy hh:mm:ss a'} />,
      hidden: isAuthorization,
    },
    {
      label: t('authDate'),
      value: <FormattedDate date={transaction.createdAt} format={'MM/dd/yyyy hh:mm:ss a'} />,
      hidden: !isAuthorization,
    },
    {
      label: t('settlementDate'),
      value: <FormattedDate date={transaction.settledAt} format={'MM/dd/yyyy hh:mm:ss a'} />,
      hidden: transaction?.paymentMethod?.type !== paymentMethodTypes.ACH_PAYMENT_METHOD,
    },
    { label: t('createdBy'), value: data.createdBy?.fullName },
    {
      label: t('customer'),
      node: (
        <Button
          label={data?.contract?.buyer?.name}
          onClick={() => navigate(`/customers/${data?.contract?.id}/overview`)}
          size={sizes.SM}
          testData="customer-link"
          variant={buttonsVariants.LINK}
        />
      ),
    },
  ]
  const paymentDescriptionData = [
    {
      label: <Text fontWeight={fontWeight.MEDIUM}>{t('totalPayment')}</Text>,
      value: <Money value={transaction.chargeAmountCents} />,
    },
    {
      label: `(+) ${t('cardConvenienceFee')}`,
      value: <Money value={feeAmountCents} />,
    },
  ]

  const [resendReceipt, { loading: isResendReceiptLoading }] = useCustomMutation({
    mutation: ResendReceiptMutation,
    rollbarOptions: { operationName: 'PaymentRequestMutation', target: 'InvoicesOutstanding' },
  })
  const { newNotification } = useNotifications()

  const handleSubmitResendPayment = (recipientsFullListValues) => {
    const recipientsViaEmail = getRecipientsViaEmail(recipientsFullListValues)
    const variables = {
      data: {
        recipientsViaEmail,
      },
      id: transaction.id, //Ask David paymentTransaction or PaymentId
    }
    resendReceipt({ variables }).then(({ data }) => {
      const responseData = data?.resendReceipt || {}
      setIsResendPaymentModalOpened(false)

      if (responseData?.entity) {
        newNotification({ success: t('paymentReceiptSuccessfullySent') })
      }
    })
  }

  return (
    <>
      <div className="flex flex-col mt-[-1.5rem]">
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
              {t('depositPayment')}
            </Text>
          </div>

          <div className="flex flex-row">
            <div className="mr-2">
              <InfoNote label={t('id')}>
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                  {id}
                </Text>
              </InfoNote>
            </div>
          </div>
        </div>

        <div className="flex flex-row mt-3">
          <div className="w-6/12 flex flex-col">
            <SidebarLabel text="depositAmount" />
            <Money className="text-2xl mt-1 font-medium" value={transaction.chargeAmountCents} />
          </div>
        </div>

        <DividedList content={descriptionData} />

        <Text className="mt-10" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
          {t('paymentDetails')}
        </Text>

        <DividedList content={paymentDescriptionData} />
        <Flex alignItems="center" className="border-t min-h-10" justifyContent="between">
          <Text fontWeight={fontWeight.BOLD}>{t('totalCharged')}</Text>
          <Money className="font-semibold text-xl" value={transaction.totalAmountCents} />
        </Flex>
        <MemoField
          closeForm={() => null}
          entityId={transaction.id}
          initialValue={data.memo}
          isFormDirty={memoFormIsDirty}
          isLoading={loading}
          labelKey="memo"
          responseEntityName={'updatePayment'}
          setDirtyFormState={setMemoFormIsDirty}
          updateEntity={updatePaymentMemo}
        />
        {transaction.displayStatus === paymentTransactionDisplayStatuses.SUCCESSFUL && (
          <Button
            className="mt-8"
            label={t('resendReceipt')}
            onClick={() => setIsResendPaymentModalOpened(true)}
            testData="resend-receipt-button"
            variant={buttonsVariants.SECONDARY}
          />
        )}
        {transaction.voidable && (
          <Button
            className="mt-8"
            label={t('void')}
            onClick={voidTransaction}
            testData="void-transaction"
            variant={buttonsVariants.SECONDARY}
          />
        )}
      </div>
      {isResendPaymentModalOpened && (
        <ResendPaymentReceiptForm
          buyerId={buyerId}
          buyerName={buyerName}
          contractId={data?.contract?.id}
          isLoading={isResendReceiptLoading}
          isOpened={isResendPaymentModalOpened}
          onSubmit={handleSubmitResendPayment}
          paymentId={transaction?.id}
          setIsOpened={setIsResendPaymentModalOpened}
        />
      )}
    </>
  )
}

DepositPaymentContent.propTypes = {
  transaction: PT.object,
  id: PT.string,
  voidTransaction: PT.func,
  isAuthorization: PT.bool,
}

DepositPaymentContent.defaultProps = {
  data: {},
  id: '',
  isAuthorization: false,
}

export default DepositPaymentContent
