import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import cx from 'classnames'
import {
  Button,
  Flex,
  InfoNote,
  Dialog,
  Money,
  StatusBadge,
  Text,
  FormattedDate,
} from '../../../ui-kit'
import Navigation from './Navigation'
import Heading from '../../../ui-kit/components/text/Heading'
import sizes from '../../../ui-kit/sizes'
import styles from './ContractHeader.module.scss'
import { getDisplayStatusValue } from '../utils'
import AddNoteForm from '../addNoteForm/AddNoteForm'
import { VendorSettingsQuery } from '../../../queries/vendors.gql'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import { ContractSettingsQuery } from '../../../queries/contracts.gql'
import ContractPreferencesForm from '../contractPreferencesForm/ContractPreferencesForm'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { isCounterUser } from '../../settings/teamManagement/membershipRoles'
import { downloadFile } from '../../reports/logic/reportsUtils'
import { useSelector } from 'react-redux'
import { selectAuthToken } from '../../../store/selectors/auth'
import { useRollbar } from '@rollbar/react'
import { DateTime } from 'luxon'
import CashApp from '../cashApp/CashApp'

const OrangeContainer = () => <div className={styles.orangeContainer} />

const ContractHeader = ({ contractData, contractId }) => {
  const currentUser = useCurrentUser()
  const [stmtInProgress, setStmtInProgress] = useState(false)
  const { data: vendorSettings } = useCustomQuery({
    query: VendorSettingsQuery,
    queryOptions: {
      variables: { id: currentUser?.vendorId, withPaymentSettings: true },
      skip: !currentUser?.vendorId,
    },
    rollbarOptions: { operationName: 'VendorSettingsQuery', target: 'ContractHeader' },
  })
  const { data: contractSettings } = useCustomQuery({
    query: ContractSettingsQuery,
    queryOptions: {
      variables: { id: contractId },
    },
    rollbarOptions: { operationName: 'ContractSettingsQuery', target: 'ContractHeader' },
  })

  const [paymentSettings, setPaymentSetting] = useState({})
  useEffect(() => {
    if (contractSettings?.contract?.paymentSettings) {
      setPaymentSetting(contractSettings?.contract?.paymentSettings)
    } else {
      setPaymentSetting(vendorSettings?.vendor?.paymentSettings)
    }
  }, [contractSettings, vendorSettings])
  const autopayEnabled = contractSettings?.contract?.autopayConfiguration
  const { t } = useTranslation()
  const nextPaymentDate =
    contractSettings?.contract?.autopayConfiguration?.day === 'as_due'
      ? t('asInvoicesComeDue')
      : contractSettings?.contract?.autopayConfiguration?.nextPaymentDate
  const containerClassnames = cx('mb-4 px-4 py-4 bg-white shadow rounded-lg overflow-hidden z-10')
  const [isAddNoteModalOpened, setIsAddNoteModalOpened] = useState(false)
  const [isCashAppModalOpened, setIsCashAppModalOpened] = useState(false)
  const [isContractPreferencesModalOpened, setIsContractPreferencesModalOpened] = useState(false)
  const closeFormAddNote = useCallback(() => {
    setIsAddNoteModalOpened(false)
  }, [setIsAddNoteModalOpened])
  const closeFormPreferences = useCallback(() => {
    setIsContractPreferencesModalOpened(false)
  }, [setIsContractPreferencesModalOpened])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm: closeFormAddNote,
  })
  const {
    isFormDirty: isFormDirtyPreferences,
    setDirtyFormState: setDirtyFormStatePreferences,
    requestClose: requestClosePreferences,
  } = useDirtyFormAlert({
    closeForm: closeFormPreferences,
  })
  const contractPreference = contractData?.contractPreference
  const authToken = useSelector(selectAuthToken)
  const rollbar = useRollbar()

  const downloadSmt = async () => {
    setStmtInProgress(true)
    const fileName = `${
      currentUser?.vendorName
    } Account Statement ${DateTime.now().toLocaleString()}.pdf`
    await downloadFile(`/contracts/${contractId}/statement.pdf`, authToken, '', fileName, rollbar)
    setStmtInProgress(false)
  }

  return (
    <div className={containerClassnames}>
      <OrangeContainer />
      <Flex alignItems={'baseline'} justifyContent={'between'}>
        <Heading className="ml-2" size={sizes.XL2}>
          {contractData.buyer?.name || ''}&nbsp;
          {autopayEnabled && (
            <StatusBadge className="ml-2 text-sm" color={'suppliOrange'} value={t('autopay')} />
          )}
        </Heading>
        {!isCounterUser(currentUser) && (
          <Flex className="gap-x-2">
            <Button
              label={t('cashApp')}
              onClick={() => setIsCashAppModalOpened(true)}
              testData="cash-app-btn"
              variant={buttonsVariants.SECONDARY}
            />
            <Button
              disabled={stmtInProgress}
              iconName="plusCircle"
              iconType="outline"
              label={t('runStmt')}
              onClick={() => downloadSmt()}
              testData="plus-add-note"
              variant={'secondary'}
            />
            <Button
              iconName="plusCircle"
              iconType="outline"
              label={t('addNote')}
              onClick={() => setIsAddNoteModalOpened(true)}
              testData="plus-add-note"
              variant={'secondary'}
            />
          </Flex>
        )}
      </Flex>
      <Flex className="mt-4 ml-2 space-x-2" gap="6" row>
        <InfoNote label={t('customerId')}>{contractData.referenceId}</InfoNote>

        <InfoNote label={t('status')}>
          <StatusBadge iconName="dot" value={getDisplayStatusValue(contractData.overdueLevel)} />
        </InfoNote>

        {vendorSettings?.vendor?.paymentSettings?.showCreditLimit && (
          <InfoNote label={t('creditLimit')}>
            <Money value={contractData.creditLimitCents} />
          </InfoNote>
        )}

        <InfoNote label={t('outstanding')}>
          <Money value={contractData.outstandingAmountCents} />
        </InfoNote>

        <InfoNote label={t('netDue')}>
          <Money value={contractData.netDueAmountCents} />
        </InfoNote>
        {vendorSettings?.vendor?.paymentSettings?.showPaymentTerms &&
          paymentSettings?.paymentTerms && (
            <InfoNote label={t('paymentTerms')}>
              <Text>{paymentSettings?.paymentTerms}</Text>
            </InfoNote>
          )}
        {!isCounterUser(currentUser) && (
          <InfoNote label={t('lastCreditReview')}>
            <FormattedDate date={contractData.lastCreditReviewDate} />
          </InfoNote>
        )}
        {!!nextPaymentDate && (
          <InfoNote label={t('nextAutopay')}>
            <FormattedDate date={nextPaymentDate} />
          </InfoNote>
        )}
      </Flex>
      <Flex justifyContent="between" testData="contract-navigation-wrapper">
        <Navigation />
        {!isCounterUser(currentUser) && (
          <Button
            label={t('contractPreferences')}
            onClick={() => setIsContractPreferencesModalOpened(true)}
            variant={buttonsVariants.LINK}
          />
        )}
      </Flex>
      <Dialog isOpened={isAddNoteModalOpened} setIsOpened={requestClose} title={t('addNote')}>
        <AddNoteForm
          closeForm={closeFormAddNote}
          contractId={contractId}
          customerName={contractData.buyer?.name || ''}
          isFormDirty={isFormDirty}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          setIsOpenedModal={setIsAddNoteModalOpened}
        />
      </Dialog>
      <Dialog
        isOpened={isContractPreferencesModalOpened}
        setIsOpened={requestClosePreferences}
        title={t('contractPreferenceForm')}>
        <ContractPreferencesForm
          closeForm={closeFormPreferences}
          contractId={contractId}
          contractPreference={contractPreference}
          isFormDirty={isFormDirtyPreferences}
          requestClose={requestClosePreferences}
          setDirtyFormState={setDirtyFormStatePreferences}
          setIsOpenedModal={setIsContractPreferencesModalOpened}
        />
      </Dialog>
      {isCashAppModalOpened && (
        <CashApp
          contractData={contractData}
          contractId={contractId}
          setIsOpened={setIsCashAppModalOpened}
          isOpened
          withPartialPayment
        />
      )}
    </div>
  )
}

ContractHeader.propTypes = {
  contractId: PT.string.isRequired,
  contractData: PT.shape({
    id: PT.string,
    referenceId: PT.string,
    buyer: PT.shape({
      name: PT.string,
    }),
    contractPreference: PT.shape({
      email: PT.bool,
      text: PT.bool,
      phone: PT.bool,
      monthlyStatement: PT.bool,
      specialInstructions: PT.string,
    }),
    availableCreditAmountCents: PT.number,
    creditLimitCents: PT.number,
    status: PT.string,
    slug: PT.string,
    outstandingAmountCents: PT.number,
    overdueLevelGroups: PT.arrayOf(
      PT.shape({
        amountCents: PT.number,
        id: PT.string,
        overdueLevel: PT.shape({
          id: PT.string,
          overdueFrom: PT.number,
          overdueTo: PT.number,
          title: PT.string,
        }),
      }),
    ),
    overdueLevel: PT.shape({
      id: PT.string,
      overdueFrom: PT.number,
      overdueTo: PT.number,
      title: PT.string,
    }),
    netDueAmountCents: PT.number,
    lastCreditReviewDate: PT.string,
  }),
}

ContractHeader.defaultProps = {
  contractData: {},
}

export default ContractHeader
