import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Icon, Text, Dialog } from '../../../ui-kit'
import { Field, Form } from 'react-final-form'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { validateRequiredField } from '../../../utils/validators'
import { ContractsQuery } from '../../../queries/contracts.gql'
import { ProjectsQuery } from '../../../queries/projects.gql'
import colors from '../../../ui-kit/colors'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import CurrencyInput from '../../../ui-kit/components/inputs/CurrencyInput'
import { getContractData } from '../invoicesUtils'
import FormSelect from '../../../ui-kit/components/Select/FormSelect'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import getPresentationName, { requestSources } from '../../../utils/getPresentationName'
import { BuyerQuery } from '../../../queries/buyers.gql'
import { LocationsQuery } from '../../../queries/locations.gql'
import EmailForm from '../requestPayment/EmailForm'
import TextForm from '../requestPayment/TextForm'
import { getModelsByIds } from '../../../utils/utils'
import { useNotifications } from '../../../hooks/useNotifications'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import { RequestDeposit } from '../../../queries/mutations/requestDeposit.gql'
import { InvoicesDepositsQuery } from '../../../queries/invoices/invoicesDeposits.gql'
import VALIDATION_ERRORS from '../../../constants/validationErrors'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const DepositRequestPaymentForm = ({
  isOpened,
  contractData,
  isAllContracts,
  isFormDirty,
  setDirtyFormState,
  closeForm,
  requestClose,
}) => {
  const { t } = useTranslation()
  const { newNotification } = useNotifications()
  const [selectedContractId, setSelectedContractId] = useState(contractData?.id || '')

  const { data, refetch } = useCustomQuery({
    query: ContractsQuery,
    queryOptions: {
      skip: !isOpened || !isAllContracts,
    },
    rollbarOptions: { operationName: 'ContractsQuery', target: 'DepositRequestPaymentForm' },
  })

  const { data: locationsData, loading: locationsLoading } = useCustomQuery({
    query: LocationsQuery,
    rollbarOptions: { operationName: 'LocationsQuery', target: 'DepositRequestPaymentForm' },
  })
  const locations = locationsData?.locations?.data

  const { data: projectsData, refetch: projectsRefetch } = useCustomQuery({
    query: ProjectsQuery,
    queryOptions: {
      skip: !isOpened || !selectedContractId,
    },
    rollbarOptions: { operationName: 'ProjectsQuery', target: 'DepositRequestPaymentForm' },
  })

  const locationsList = useMemo(
    () =>
      locations?.map?.((item) => ({
        value: item.id,
        label: item.name,
        testData: item.name,
      })) || [],
    [locations],
  )
  const contractsList = useMemo(
    () =>
      isAllContracts
        ? data?.contracts?.data?.map?.((item) => ({
            value: item.id,
            label: item.buyer?.name,
          })) || []
        : [{ value: contractData?.id, label: contractData?.buyer?.name }],
    [data, isAllContracts],
  )

  const { contractData: fetchedContractData, loading: contractDataLoading } = getContractData(
    isAllContracts && selectedContractId,
  )

  const [requestDeposit, { loading }] = useCustomMutation({
    mutation: RequestDeposit,
    rollbarOptions: { operationName: 'RequestDeposit', target: 'DepositRequestPaymentForm' },
    mutationOptions: {
      refetchQueries: [InvoicesDepositsQuery],
    },
  })

  const initialValues = useMemo(
    () => ({
      memo: '',
      contractId: contractData?.id || '',
      amountCents: '',
      locationId: '',
      projectId: '',
    }),
    [contractData],
  )

  useEffect(() => {
    if (!isOpened) {
      setSelectedContractId(contractData?.id || '')
    }
  }, [isOpened, contractData])

  const loadOptions = useCallback(
    async (search, values) => {
      try {
        const { data: loadedData } = await refetch({
          search: search || '',
          sort: 'name.asc',
          page: !values?.length ? 1 : parseInt(data?.contracts?.paginationData.to / 10) + 1 || 1,
        })

        if (loadedData) {
          return {
            options: loadedData.contracts.data.map((option) => ({
              label: option.buyer?.name,
              value: option.id,
            })),
            hasMore:
              loadedData.contracts.paginationData &&
              loadedData.contracts.paginationData.to <
                loadedData.contracts.paginationData.totalCount,
          }
        }
      } catch (err) {
        return {
          options: [],
          hasMore: false,
        }
      }
    },
    [refetch, data],
  )

  const emptyOption = useMemo(() => ({ label: t('unassigned'), value: '' }), [t])
  const loadProjectsOptions = useCallback(
    async (search, values) => {
      const isFirstOptionEmpty =
        values[0] && values[0].label === emptyOption.label && values[0].value === emptyOption.value
      const valuesLength = isFirstOptionEmpty ? values.length - 1 : values.length
      const nextPage = !valuesLength
        ? 1
        : parseInt(projectsData?.projects?.paginationData.to / 10) + 1 || 1
      try {
        const { data: loadedData } = await projectsRefetch({
          search: search || '',
          sort: 'name.asc',
          page: nextPage,
          contractId: selectedContractId,
        })

        if (loadedData) {
          const options = loadedData.projects.data.map((option) => ({
            label: option.name,
            value: option.id,
          }))
          return {
            options: isFirstOptionEmpty ? options : [emptyOption, ...options],
            hasMore:
              loadedData.projects.paginationData &&
              loadedData.projects.paginationData.to < loadedData.projects.paginationData.totalCount,
          }
        }
      } catch (err) {
        return {
          options: [],
          hasMore: false,
        }
      }
    },
    [projectsRefetch, projectsData, selectedContractId, emptyOption],
  )

  const recipientsRef = useRef(null)
  const [listOfRecipients, setListOfRecipients] = useState([])
  const [guestEmails, setGuestEmails] = useState([])
  const [guestPhones, setGuestPhones] = useState([])
  const [recipientsFullListValues, setRecipientsFullListValues] = useState([])
  const [guestEmailModalIsOpened, setGuestEmailModalIsOpened] = useState(false)
  const [guestPhoneModalIsOpened, setGuestPhoneModalIsOpened] = useState(false)
  const buyerVariables = useMemo(
    () => ({
      id: isAllContracts ? fetchedContractData?.buyer?.id : contractData?.buyer?.id,
      withAddresses: false,
    }),
    [isAllContracts, contractData, fetchedContractData],
  )
  const { data: buyerData, loading: buyerDataLoading } = useCustomQuery({
    query: BuyerQuery,
    queryOptions: {
      variables: buyerVariables,
      skip: !buyerVariables.id,
    },
    rollbarOptions: { operationName: 'BuyerQuery', target: 'DepositRequestPaymentForm' },
  })
  const users = useMemo(
    () => buyerData?.buyer?.users?.filter((user) => user.companyRole !== 'view_only') || [],
    [buyerData],
  )

  const onChangeRecipients = useCallback(
    (e) => {
      setListOfRecipients(e.target.value)
      recipientsRef.current.handleExternalClose()
    },
    [recipientsRef],
  )

  const onEmailClick = useCallback(() => {
    setGuestEmailModalIsOpened(true)
  }, [])

  const onPhoneClick = useCallback(() => {
    setGuestPhoneModalIsOpened(true)
  }, [])

  const getRecipientsOptions = useCallback(() => {
    const emails = users.reduce((acc, user) => {
      if (!user.email) {
        return acc
      }

      acc.push({
        label: () => (
          <div className="text-sm ml-6">
            {getPresentationName({ user }, t, requestSources.EMAIL)}
          </div>
        ),
        value: `${user.id}/email`,
      })
      return acc
    }, [])
    const phones = users.reduce((acc, user) => {
      if (!user.formattedPhoneNumber) {
        return acc
      }

      acc.push({
        label: () => (
          <div className="text-sm ml-6">
            {getPresentationName({ user }, t, requestSources.PHONE)}
          </div>
        ),
        value: `${user.id}/phone`,
      })
      return acc
    }, [])
    emails.push({
      label: () => (
        <div
          className={`${colors.BLUE} capitalize text-sm w-[30rem] mb-2 ml-6`}
          onClick={onEmailClick}>
          {`+ ${t('addEmail')}`}
        </div>
      ),
      value: 'addEmail',
    })
    phones.push({
      label: () => (
        <div
          className={`${colors.BLUE} capitalize text-sm w-full w-[30rem] ml-6`}
          onClick={onPhoneClick}>
          {`+ ${t('addMobilePhone')}`}
        </div>
      ),
      value: 'addMobilePhone',
    })

    const recipients = []

    if (emails.length) {
      recipients.push({
        label: t('email'),
        options: emails,
      })
    }

    if (phones.length) {
      recipients.push({
        label: t('text'),
        options: phones,
      })
    }

    return recipients
  }, [t, users, onEmailClick, onPhoneClick])

  const handleAddNewEmail = useCallback(
    (values) => {
      const copyGuestEmails = [...guestEmails]
      copyGuestEmails.push(values)
      setGuestEmails(copyGuestEmails)
      setGuestEmailModalIsOpened(false)
    },
    [guestEmails],
  )

  const handleAddNewPhone = useCallback(
    (values) => {
      const copyGuestPhones = [...guestPhones]
      copyGuestPhones.push(values)
      setGuestPhones(copyGuestPhones)
      setGuestPhoneModalIsOpened(false)
    },
    [guestPhones],
  )

  const handleDelete = useCallback(
    (item) => {
      const copyRecipients = listOfRecipients.filter((recipient) => recipient !== item.id)
      setListOfRecipients(copyRecipients)
      const copyGuestEmails = guestEmails.filter((recipient) => recipient.email !== item.id)
      setGuestEmails(copyGuestEmails)
      const copyGuestPhones = guestPhones.filter((recipient) => recipient.phone !== item.id)
      setGuestPhones(copyGuestPhones)
    },
    [listOfRecipients, guestEmails, guestPhones],
  )

  useEffect(() => {
    const emails = listOfRecipients
      .filter((value) => value && value.indexOf('email') > -1)
      .map((value) => value.replace('/email', ''))
    const phones = listOfRecipients
      .filter((value) => value && value.indexOf('phone') > -1)
      .map((value) => value.replace('/phone', ''))

    const selectedUserEmails = getModelsByIds(users, emails)
    const presentationValuesEmails = selectedUserEmails.map((user) => ({
      presentedName: getPresentationName({ user }, t, requestSources.EMAIL),
      sourceEmail: user.email,
      id: `${user.id}/email`,
    }))
    presentationValuesEmails.push(
      ...guestEmails.map((user) => ({
        presentedName: t('guestUser'),
        sourceEmail: user.email,
        id: user.email,
      })),
    )

    const selectedUserPhones = getModelsByIds(users, phones)
    const presentationValuesPhones = selectedUserPhones.map((user) => ({
      presentedName: getPresentationName({ user }, t, requestSources.PHONE),
      sourcePhone: user.formattedPhoneNumber,
      id: `${user.id}/phone`,
    }))
    presentationValuesPhones.push(
      ...guestPhones.map((user) => ({
        presentedName: t('guestUser'),
        sourcePhone: user.phone,
        id: user.phone,
      })),
    )
    setRecipientsFullListValues([...presentationValuesEmails, ...presentationValuesPhones])
  }, [listOfRecipients, guestEmails, guestPhones])

  const onSubmit = useCallback(
    (values) => {
      if (!recipientsFullListValues.length) {
        return
      }

      const recipientsViaEmail = recipientsFullListValues
        .filter((item) => !!item.sourceEmail)
        .map((item) => ({
          ...(item.id && item.id !== item.sourceEmail
            ? { buyerUserId: item.id.split('/')[0] }
            : {}),
          email: item.sourceEmail,
        }))
      const recipientsViaText = recipientsFullListValues
        .filter((item) => !!item.sourcePhone)
        .map((item) => ({
          ...(item.id && item.id !== item.sourcePhone
            ? { buyerUserId: item.id.split('/')[0] }
            : {}),
          phoneNumber: item.sourcePhone,
        }))
      const amountCentsValue = Number(values.amountCents.replaceAll(',', ''))

      const variables = {
        data: {
          ...values,
          locationId: values.locationId,
          amountCents: Math.round(amountCentsValue * 100),
          recipientsViaEmail,
          recipientsViaText,
          overpayment: false,
          projectId: values.projectId || null,
        },
      }

      requestDeposit({ variables }).then(({ data }) => {
        const responseData = data?.requestDeposit || {}

        if (responseData?.entity) {
          newNotification({ success: t('requestSuccessfullySent') })
          closeForm()
        }
      })
    },
    [recipientsFullListValues],
  )

  return (
    <Form
      initialValues={initialValues}
      mutators={{
        clearSelectedProject: (args, state, utils) => {
          utils.changeValue(state, 'projectId', () => '')
        },
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, dirty, submitSucceeded, submitFailed, form }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <form className={'overflow-hidden'} onSubmit={handleSubmit}>
            <div className="w-full mt-6">
              <Field name="contractId">
                {({ input, ...fieldProps }) => {
                  return (
                    <>
                      <FormSelect
                        input={input}
                        {...fieldProps}
                        error={
                          fieldProps.meta.error && fieldProps.meta.submitFailed
                            ? fieldProps.meta.error
                            : undefined
                        }
                        id={input.name}
                        isDisabled={!isAllContracts}
                        label={t('customer')}
                        loadOptions={loadOptions}
                        onChange={(val) => {
                          input.onChange(val)
                          setSelectedContractId(val)
                          form.mutators.clearSelectedProject()
                        }}
                        options={contractsList || []}
                        placeholder={t('customer')}
                        testData="contracts"
                        touched={fieldProps.meta.touched}
                        value={input.value}
                        withCheckmarks
                      />
                    </>
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="projectId">
                {({ input, ...fieldProps }) => {
                  return (
                    <>
                      <FormSelect
                        input={input}
                        {...fieldProps}
                        cacheUniqs={[selectedContractId]}
                        error={
                          fieldProps.meta.error && fieldProps.meta.submitFailed
                            ? fieldProps.meta.error
                            : undefined
                        }
                        id={input.name}
                        isDisabled={!selectedContractId}
                        label={t('project')}
                        loadOptions={loadProjectsOptions}
                        onChange={input.onChange}
                        options={[emptyOption]}
                        placeholder={t('project')}
                        testData="projects"
                        touched={fieldProps.meta.touched}
                        value={input.value}
                        withCheckmarks
                      />
                    </>
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="locationId">
                {({ input, ...fieldProps }) => {
                  return (
                    <>
                      <NDropdown
                        errorMessage={
                          fieldProps.meta.error && fieldProps.meta.submitFailed
                            ? fieldProps.meta.error
                            : undefined
                        }
                        isDisabled={locationsLoading}
                        label={t('location')}
                        listClass="max-h-40"
                        onChange={input.onChange}
                        options={locationsList || []}
                        placeholder={t('location')}
                        testData="locations"
                        value={input.value}
                        withSingleOptionAutoSelection
                      />
                    </>
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="amountCents">
                {({ input, meta }) => {
                  return (
                    <Flex column>
                      <Text
                        className="pb-1"
                        color={colors.GRAY_700}
                        fontWeight={fontWeight.MEDIUM}
                        size={sizes.SM}>
                        {t('amount')}
                      </Text>
                      <CurrencyInput
                        error={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        name={input.name}
                        onChange={input.onChange}
                      />
                      {meta.error && meta.touched && meta.submitFailed ? (
                        <p className="pt-2 text-sm text-error">{meta.error}</p>
                      ) : null}
                    </Flex>
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="memo">
                {({ input, meta }) => {
                  return (
                    <Textarea
                      {...input}
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      inputClassName="h-44"
                      label={t('memoOptional')}
                      placeholder={t('memo')}
                    />
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <NDropdown
                className="mt-6"
                inputClassName="w-full"
                isDisabled={!selectedContractId || contractDataLoading || buyerDataLoading}
                label={t('addRecipients')}
                listClass="max-h-40"
                onChange={onChangeRecipients}
                options={getRecipientsOptions()}
                placeholder={t('addRecipients')}
                ref={recipientsRef}
                testData="recipients"
                value={listOfRecipients.filter(
                  (value) => value !== 'addEmail' && value !== 'addMobilePhone',
                )}
                isHiddenInputValue
                isMultipleSelect
                withCheckmarks
              />
              {(submitSucceeded || submitFailed) && !recipientsFullListValues.length && (
                <p className="mt-2 text-sm text-error">{VALIDATION_ERRORS.RECIPIENT_REQUIRED}</p>
              )}
            </div>

            <div className="w-full mt-6">
              <Text>{t('sendTo')}</Text>
              <div className="w-full border mt-1 rounded-md min-h-[8rem] py-2 px-2">
                {!recipientsFullListValues.length && (
                  <Text color="text-black-500">{t('addRecipients')}</Text>
                )}
                {!!recipientsFullListValues.length &&
                  recipientsFullListValues.map((item) => (
                    <Flex justifyContent="between mb-1" key={item.email}>
                      <Text className="pr-1">{item.presentedName}</Text>
                      <span>
                        {!!item.sourceEmail && (
                          <a
                            className={'text-blue-500 hover:text-blue-500'}
                            href={`mailto:${item.sourceEmail}`}>
                            {item.sourceEmail}
                          </a>
                        )}
                        {!!item.sourcePhone && (
                          <a
                            className={'text-blue-500 hover:text-blue-500'}
                            href={`tel:${item.sourcePhone}`}>
                            {item.sourcePhone}
                          </a>
                        )}
                        <Icon
                          className="w-8 inline"
                          color="text-warmBlack-400"
                          name="trash"
                          onClick={() => handleDelete(item)}
                          type="outline"
                        />
                      </span>
                    </Flex>
                  ))}
              </div>
            </div>

            <div className="w-full mt-6 flex flex-row justify-end">
              <Button
                className="mr-2"
                label={t('cancel')}
                onClick={requestClose}
                testData="cancel-request-dep"
                variant={buttonsVariants.TERTIARY}
              />
              <Button
                disabled={loading}
                label={t('requestPayment')}
                testData="submit-request-dep"
                type="submit"
              />
            </div>

            <AlertModal confirmClose={closeForm} />

            <Dialog
              isOpened={guestEmailModalIsOpened}
              setIsOpened={setGuestEmailModalIsOpened}
              shouldCloseOnBackdropClick>
              <EmailForm handleSubmitForm={handleAddNewEmail} />
            </Dialog>

            <Dialog
              isOpened={guestPhoneModalIsOpened}
              setIsOpened={setGuestPhoneModalIsOpened}
              shouldCloseOnBackdropClick>
              <TextForm handleSubmitForm={handleAddNewPhone} />
            </Dialog>
          </form>
        )
      }}
      validate={(values) => ({
        contractId: validateRequiredField(values.contractId),
        locationId: validateRequiredField(values.locationId),
        amountCents: validateRequiredField(values.amountCents),
      })}
    />
  )
}

DepositRequestPaymentForm.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  closeForm: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  contractData: PropTypes.shape({
    aging: PropTypes.number,
    id: PropTypes.string,
    customerName: PropTypes.string,
    outstandingAmountCents: PropTypes.number,
    availableCreditAmountCents: PropTypes.number,
    availableDepositAmountCents: PropTypes.number,
    buyer: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      paymentMethods: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
      users: PropTypes.arrayOf(PropTypes.any),
    }),
  }),
  isAllContracts: PropTypes.bool.isRequired,
  setDirtyFormState: PropTypes.func.isRequired,
}

export default DepositRequestPaymentForm
