import PT from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateBuyerUserMutation } from '../../../../../queries/mutations/usersRequest.gql'
import { useNotifications } from '../../../../../hooks/useNotifications'
import { buyerUserAttributes } from './buyerUserAttributes'
import { useCustomMutation } from '../../../../../hooks/useCustomMutation'
import BuyerUserForm from './BuyerUserForm'
import {
  getExtendedNotificationSettings,
  getMappedNotificationSettings,
  mapEditUserInitialValues,
} from './utils'
import { useCustomQuery } from '../../../../../hooks/useCustomQuery'
import { ContractNotificationQuery } from '../../../../../queries/contracts.gql'

const InviteBuyerUserForm = ({
  buyerId,
  setIsOpenedModal,
  refetch,
  closeForm,
  isFormDirty,
  requestClose,
  setDirtyFormState,
  contractId,
}) => {
  const { t } = useTranslation()

  const { data: notificationsData } = useCustomQuery({
    query: ContractNotificationQuery,
    queryOptions: {
      variables: { id: contractId },
    },
    rollbarOptions: { operationName: 'BuyerUserQuery', target: 'EditContact' },
  })
  const getInitValues = () => {
    if (!notificationsData?.contract?.contractNotificationSettings) {
      return {}
    }

    const { notificationTypes: contractNotificationTypes } =
      notificationsData.contract.contractNotificationSettings
    const userNotificationTypes = []

    const extendedNotificationConfig = getExtendedNotificationSettings(
      contractNotificationTypes,
      userNotificationTypes,
      true,
    )

    return mapEditUserInitialValues(extendedNotificationConfig)
  }

  const initialValues = useMemo(
    () => ({
      ...buyerUserAttributes,
      notificationSettings: getInitValues(),
    }),
    [notificationsData],
  )

  const [create, { loading }] = useCustomMutation({
    mutation: CreateBuyerUserMutation,
    rollbarOptions: { operationName: 'CreateBuyerUserMutation', target: 'InviteBuyerUserForm' },
  })
  const { newNotification } = useNotifications()
  const createUser = async (variables) => {
    const { data } = await create({ variables })
    const responseData = data?.createBuyerUser || {}

    if (responseData?.entity) {
      newNotification({ success: t('vendorUserAddedSuccessfully') })
      refetch()
      setIsOpenedModal(false)
    }
  }
  const handleSubmitForm = async (values) => {
    const data = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      extension: values.extension,
      phoneNumber: values.phoneNumber,
      companyRole: values.companyRole,
      officePhoneNumber: values.officePhoneNumber,
      notificationTypes: getMappedNotificationSettings(values.notificationSettings),
    }

    const variables = {
      data,
      buyerId,
      sendInvitation: values.sendInvitation,
    }

    const formError = await createUser(variables)

    if (formError) {
      return formError
    }
  }

  return (
    <BuyerUserForm
      cancelButtonTestData="cancel-invite-user"
      closeForm={closeForm}
      handleSubmitForm={handleSubmitForm}
      initialValues={initialValues}
      isFormDirty={isFormDirty}
      loading={loading}
      requestClose={requestClose}
      setDirtyFormState={setDirtyFormState}
      submitButtonLabelKey="add"
      submitButtonTestData="submit-add-user"
    />
  )
}

InviteBuyerUserForm.propTypes = {
  buyerId: PT.oneOfType([PT.string, PT.number]).isRequired,
  closeForm: PT.func.isRequired,
  isFormDirty: PT.bool.isRequired,
  requestClose: PT.func.isRequired,
  setIsOpenedModal: PT.func,
  setDirtyFormState: PT.func.isRequired,
  refetch: PT.func,
  contractId: PT.string,
}

InviteBuyerUserForm.defaultProps = {
  refetch: () => {},
}

export default InviteBuyerUserForm
