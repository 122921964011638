import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Flex, Text, FormattedDate } from '../../../../ui-kit'
import sizes from '../../../../ui-kit/sizes'
import Button from '../../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import Toggle from '../../../../ui-kit/components/inputs/Toggle'
import { Form, Field } from 'react-final-form'
import PaymentType from '../../../../components/paymentType/PaymentType'
import { getPaymentTypesOptions } from '../../../invoices/invoicesUtils'
import { CreateOrUpdateAutopayConfiguration } from '../../../../queries/mutations/createOrUpdateAutopayConfiguration.gql'
import { DeleteContractAutopayConfiguration } from '../../../../queries/mutations/deleteContractAutopayConfiguration.gql'
import {
  ContractAutopayConfiguration,
  ContractSettingsQuery,
} from '../../../../queries/contracts.gql'
import { useNotifications } from '../../../../hooks/useNotifications'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'
import NDropdown from '../../../../ui-kit/components/dropdown/NDropdown'
import { validateRequiredField } from '../../../../utils/validators'
import { convertTimeZone, getAutopayOptions, getNextAutopayDate } from './utils'
import { useCurrentUser } from '../../../../hooks/useCurrentUser'

const AutopayModalContent = ({
  hideModal,
  autopayEnabled,
  paymentMethodsData,
  autopayConfiguration,
  contractData,
  refetchPaymentMethods,
  contractPaymentSettings,
}) => {
  const { t } = useTranslation()

  const initialValues = {
    autopayEnabled: autopayEnabled,
    day: autopayConfiguration?.day,
    paymentMethod: autopayConfiguration?.paymentMethod?.id,
  }

  const [paymentTypesState, setPaymentTypesState] = useState([])
  const [selectedPaymentType, setSelectedPaymentType] = useState()
  const onCompleted = useCallback((response) => {
    if (
      response?.createOrUpdateContractAutopayConfiguration?.entity ||
      response?.deleteContractAutopayConfiguration?.entity
    ) {
      hideModal()
      newNotification({ success: t('autopaySettingsWereChanged') })
    }
  }, [])
  const [createOrUpdateAutopayConfiguration, { loading }] = useCustomMutation({
    mutation: CreateOrUpdateAutopayConfiguration,
    onCompleted,
    rollbarOptions: {
      operationName: 'CreateOrUpdateAutopayConfiguration',
      target: 'AutopayModalContent',
    },
    mutationOptions: {
      refetchQueries: [ContractSettingsQuery, ContractAutopayConfiguration],
    },
  })
  const [deleteContractAutopayConfiguration, { loading: loadingDeleteContractAutopay }] =
    useCustomMutation({
      mutation: DeleteContractAutopayConfiguration,
      onCompleted,
      rollbarOptions: {
        operationName: 'DeleteContractAutopayConfiguration',
        target: 'AutopayModalContent',
      },
      mutationOptions: {
        refetchQueries: [ContractSettingsQuery, ContractAutopayConfiguration],
      },
    })
  const { newNotification } = useNotifications()

  const autopayOptions = useMemo(() => {
    return getAutopayOptions(t) || []
  }, [t])

  useEffect(() => {
    if (paymentMethodsData?.paymentMethods) {
      setPaymentTypesState(getPaymentTypesOptions(paymentMethodsData, t))
      const selectedPaymentMethod = paymentMethodsData.paymentMethods.find(
        (method) => method.id === autopayConfiguration?.paymentMethod?.id,
      )
      setSelectedPaymentType(selectedPaymentMethod)
    }
  }, [paymentMethodsData, autopayConfiguration])

  const handleSetAutopay = (values) => {
    if (values.autopayEnabled) {
      createOrUpdateAutopayConfiguration({
        variables: {
          contractId: contractData?.id,
          data: {
            paymentMethodId: values.paymentMethod,
            day: typeof values.day === 'number' ? values.day.toString() : values.day,
          },
        },
      })
    } else {
      deleteContractAutopayConfiguration({
        variables: {
          contractId: contractData?.id,
        },
      })
    }
  }
  const submitAutopay = (values) => {
    handleSetAutopay(values)
  }
  const rowClasses = 'lg:py-5 lg:px-6 md:px-4 md:py-3 flex'
  const currentUser = useCurrentUser()
  const vendorPaymentSettings = currentUser?.paymentSettings
  const timeZone = currentUser?.timeZone
  const convenienceFee =
    contractPaymentSettings?.creditCardFeeEnabled &&
    vendorPaymentSettings.creditCardFeeForAutopayEnabled &&
    selectedPaymentType?.data?.['credit-card']
      ? vendorPaymentSettings.creditCardFeePercentage
      : 0

  return (
    <>
      <Flex className="min-w-[30rem] pr-2" column>
        <Text color="text-warmBlack-400 pt-2 w-[35rem]" size={sizes.SM}>
          {t('autopaysRunDescription', { timeZone: convertTimeZone(timeZone) })}
        </Text>
        <Form
          initialValues={initialValues}
          mutators={{
            setPaymentMethod: (args, state, utils) => {
              const paymentMethod = args[0]?.id
              if (paymentMethod) {
                setSelectedPaymentType(args[0])
                utils.changeValue(state, 'paymentMethod', () => paymentMethod)
              }
            },
          }}
          onSubmit={submitAutopay}
          render={({ form, handleSubmit, values, submitting, dirty }) => {
            return (
              <form className={'flex flex-col justify-between mt-4'} onSubmit={handleSubmit}>
                <Flex className={'w-full'} column>
                  <ul className="divide-y divide-gray-200">
                    <li className={rowClasses}>
                      <Field name={'autopayEnabled'}>
                        {({ input }) => (
                          <Flex className={'w-full'} justifyContent={'between'}>
                            <Text color="text-black-700">{t('enrollInAutopay')}</Text>
                            <Toggle
                              disabled={submitting}
                              handleChange={input.onChange}
                              value={input.value}
                              isMobile
                            />
                          </Flex>
                        )}
                      </Field>
                    </li>
                    <li className={rowClasses}>
                      <Flex alignItems={'center'} className={'w-full'} justifyContent={'between'}>
                        <div className="w-1/2">
                          <Flex className="w-full" column>
                            <div className="w-full">
                              <Text color="text-black-700">{t('selectPaymentMethod')}</Text>
                            </div>
                            <div className="w-full">
                              {!!convenienceFee && values.autopayEnabled && (
                                <Text color="text-warmBlack-400 pt-2 text-error" size={sizes.XS}>
                                  {t('cardFeeWillBeApplied', { convenienceFee })}
                                </Text>
                              )}
                            </div>
                          </Flex>
                        </div>
                        <div className="w-1/2">
                          <Field name="paymentMethod">
                            {({ input, meta }) => {
                              return (
                                <>
                                  <PaymentType
                                    contractID={contractData?.id}
                                    disabled={!values.autopayEnabled || submitting}
                                    name={input.name}
                                    onChange={input.onChange}
                                    onCreateMethod={refetchPaymentMethods}
                                    selected={input.value}
                                    setSelected={form.mutators.setPaymentMethod}
                                    types={paymentTypesState}
                                    fullWidth
                                    noDefault
                                    noLabel
                                  />
                                  {meta.error && meta.touched && meta.submitFailed ? (
                                    <p className="pt-2 text-sm text-error">{meta.error}</p>
                                  ) : null}
                                </>
                              )
                            }}
                          </Field>
                        </div>
                      </Flex>
                    </li>
                    <li className={rowClasses}>
                      <Flex className="w-full" column>
                        <Flex alignItems={'center'} className={'w-full'} justifyContent={'between'}>
                          <Flex className="w-1/2" column>
                            <Flex className="w-full">
                              <Text color="text-black-700">{t('selectRunAutopay')}</Text>
                            </Flex>
                            <Flex className="w-full">
                              {values?.day && values.autopayEnabled && values?.day === 'as_due' && (
                                <Text color="text-warmBlack-400 pt-2 text-error" size={sizes.XS}>
                                  {t('asInvoicesComeDue')}
                                </Text>
                              )}
                              {values?.day && values.autopayEnabled && values?.day !== 'as_due' && (
                                <Text color="text-warmBlack-400 pt-2 text-error" size={sizes.XS}>
                                  {t('nextAutopayDate')}&nbsp;
                                  <FormattedDate
                                    date={getNextAutopayDate(values?.day, timeZone)}
                                    format={'MM/dd/yyyy'}
                                  />
                                </Text>
                              )}
                            </Flex>
                          </Flex>
                          <div className="w-1/2">
                            <Field name="day">
                              {({ input, meta }) => {
                                return (
                                  <>
                                    <NDropdown
                                      id={input.name}
                                      isDisabled={!values.autopayEnabled || submitting}
                                      name={input.name}
                                      onChange={input.onChange}
                                      options={autopayOptions}
                                      placeholder={t('chooseDayOfMonth')}
                                      value={input.value}
                                    />
                                    {meta.error && meta.touched && meta.submitFailed ? (
                                      <p className="pt-2 text-sm text-error">{meta.error}</p>
                                    ) : null}
                                  </>
                                )
                              }}
                            </Field>
                          </div>
                        </Flex>
                      </Flex>
                    </li>
                  </ul>
                </Flex>
                <Flex className="mt-4" justifyContent={'end'}>
                  <Button
                    className={'mr-4'}
                    disabled={
                      submitting ||
                      (!dirty &&
                        autopayConfiguration?.paymentMethod?.id === selectedPaymentType?.id) ||
                      loading ||
                      loadingDeleteContractAutopay
                    }
                    label={t('save')}
                    type={'submit'}
                    variant={buttonsVariants.PRIMARY}
                  />
                  <Button
                    label={t('cancel')}
                    onClick={hideModal}
                    variant={buttonsVariants.SECONDARY}
                  />
                </Flex>
              </form>
            )
          }}
          validate={(values) => ({
            day: validateRequiredField(values.day),
            paymentMethod: validateRequiredField(values.paymentMethod),
          })}
        />
      </Flex>
    </>
  )
}

export default AutopayModalContent

AutopayModalContent.propTypes = {
  contractPaymentSettings: PT.object,
  hideModal: PT.func.isRequired,
  autopayEnabled: PT.bool.isRequired,
  autopayAmountCents: PT.number,
  contractData: PT.object,
  refetchPaymentMethods: PT.func,
  autopayConfiguration: {
    paymentMethod: PT.object,
    day: PT.string,
  },
  paymentMethodsData: {
    paymentMethods: PT.arrayOf(
      PT.shape({
        value: PT.shape({
          name: PT.string,
          label: PT.string,
          value: PT.string,
        }),
        options: PT.arrayOf(
          PT.shape({
            name: PT.string,
            label: PT.string,
            value: PT.string,
            typeLabel: PT.string,
            default: PT.bool,
          }),
        ),
      }),
    ),
  },
  onCreateMethod: PT.func.isRequired,
}
