import { useMemo, useRef } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Icon, Text, Tooltip } from '../../../../ui-kit'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import colors from '../../../../ui-kit/colors'
import sizes from '../../../../ui-kit/sizes'
import PropTypes from 'prop-types'
import {
  getUploadedFiles,
  maxAllowedMb,
  validateAllowedExtensions,
  validateMaxAllowedFileSize,
} from '../../../settings/ErpFilesImport'
import { useNotifications } from '../../../../hooks/useNotifications'

const UpdateAttachmentForm = ({ file, onSubmit, fileRequired, setIsOpenedModal }) => {
  const { t } = useTranslation()
  const fileInputRef = useRef(null)
  const initialValues = useMemo(() => ({ file }), [file])
  const { newNotification } = useNotifications()

  const readFiles = (e) => {
    const uploadedFiles = getUploadedFiles(e)

    if (!uploadedFiles) {
      return
    }

    const file = uploadedFiles[0]
    const splitFileName = file.name.split('.').filter(Boolean)
    const extension = splitFileName[splitFileName.length - 1]
      ? `.${splitFileName[splitFileName.length - 1]}`
      : ''

    if (!validateAllowedExtensions([extension], file.name)) {
      newNotification({ error: t('uploadedFileUnsupportedFormat', { extensions: extension }) })
      return
    }

    if (!validateMaxAllowedFileSize(file.size)) {
      newNotification({ error: t('uploadedFileExceedsSizeLimit', { size: maxAllowedMb }) })
      return
    }

    if (fileInputRef?.current) {
      fileInputRef.current.value = ''
    }

    return file
  }

  return (
    <Form
      initialValues={initialValues}
      mutators={{
        setFile: (args, state, utils) => {
          utils.changeValue(state, 'file', () => args[0])
        },
      }}
      onSubmit={onSubmit}>
      {({ form, values, handleSubmit }) => {
        return (
          <form className="flex flex-col max-w-[45rem] w-full py-4" onSubmit={handleSubmit}>
            <Flex alignItems="center" className="gap-2">
              <input
                onChange={(e) => form.mutators.setFile(readFiles(e))}
                ref={fileInputRef}
                style={{ display: 'none' }}
                type="file"
              />
              <Button
                label={t('chooseFile')}
                onClick={() => fileInputRef?.current?.click()}
                variant={buttonsVariants.SECONDARY}
              />
              {values.file && (
                <Flex className="flex-1 gap-2">
                  <Tooltip className="flex" content={values.file.name} placement="top">
                    <Text className="w-[200px] text-ellipsis overflow-hidden whitespace-nowrap">
                      {values.file.name}
                    </Text>
                  </Tooltip>
                  <Icon
                    className="self-center"
                    color={colors.GREY}
                    name="trash"
                    onClick={() => form.mutators.setFile(void 0)}
                    size={sizes.LG}
                  />
                </Flex>
              )}
            </Flex>
            <Flex className="w-full mt-6" justifyContent="end">
              <Button
                label={t('cancel')}
                onClick={() => setIsOpenedModal(false)}
                testData="cancel-attachment"
                variant={buttonsVariants.TERTIARY}
              />
              <Button
                className="w-36 ml-3"
                disabled={fileRequired ? !values.file : false}
                label={t('save')}
                testData="save-attachment"
                type="submit"
              />
            </Flex>
          </form>
        )
      }}
    </Form>
  )
}

UpdateAttachmentForm.propTypes = {
  file: PropTypes.object,
  onSubmit: PropTypes.func,
  fileRequired: PropTypes.func,
  setIsOpenedModal: PropTypes.func,
}

export default UpdateAttachmentForm
