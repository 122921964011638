import React, { useCallback, useState, useMemo } from 'react'
import cx from 'classnames'
import {
  Button,
  LoadingSpinner,
  NarrowList,
  Text,
  Dialog,
  Tooltip,
  ConfirmationDialog,
  FormattedDate,
  Flex,
} from '../../ui-kit'
import sizes from '../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import styles from './teamManagement/styles.module.scss'
import EditUserForm from '../../components/user/EditUserForm'
import InviteUserForm from '../../components/user/InviteUserForm'
import { VendorSettingsQuery } from '../../queries/vendors.gql'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import UserActions from './teamManagement/UserActions'
import {
  ArchiveVendorUserMutation,
  InviteVendorUserMutation,
} from '../../queries/mutations/usersRequest.gql'
import { useNotifications } from '../../hooks/useNotifications'
import { useDirtyFormAlert } from '../../hooks/useDirtyFormAlert'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import colors from '../../ui-kit/colors'
import fontWeight from '../../ui-kit/fontWeight'
import Heading from '../../ui-kit/text/Heading'
import { membershipRoles } from './teamManagement/membershipRoles'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useCustomMutation } from '../../hooks/useCustomMutation'

const TeamManagement = () => {
  const { t } = useTranslation()
  const [editableUser, setEditableUser] = useState(null)
  const [isOpenedDeleteModal, setIsOpenedDeleteModal] = useState(false)
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false)

  const currentUser = useCurrentUser()

  const handleEditUser = (user) => {
    setEditableUser(user)
    setIsEditUserModalOpen(true)
  }

  const { data, loading, refetch } = useCustomQuery({
    query: VendorSettingsQuery,
    queryOptions: {
      variables: { id: currentUser?.vendorId, withUsers: true },
    },
    rollbarOptions: { operationName: 'VendorSettingsQuery', target: 'TeamManagement' },
  })
  const [archiveMutation, { loading: isArchiveMutationLoading }] = useCustomMutation({
    mutation: ArchiveVendorUserMutation,
    rollbarOptions: { operationName: 'ArchiveVendorUserMutation', target: 'TeamManagement' },
    mutationOptions: {
      refetchQueries: [VendorSettingsQuery],
    },
  })
  const { newNotification } = useNotifications()

  const handleArchive = () => {
    const variables = { id: editableUser.id }
    archiveMutation({ variables }).then(({ data }) => {
      const responseData = data?.archiveVendorUser || {}

      if (responseData?.entity) {
        newNotification({ success: t('userWasDeletedSuccessfully') })
        setIsOpenedDeleteModal(false)
        return
      }

      setIsOpenedDeleteModal(false)
    })
  }

  const handleDeleteUser = (user) => {
    setEditableUser(user)
    setIsOpenedDeleteModal(true)
  }

  const [inviteMutation, { loading: isInviteMutationLoading }] = useCustomMutation({
    mutation: InviteVendorUserMutation,
    rollbarOptions: { operationName: 'InviteVendorUserMutation', target: 'TeamManagement' },
    mutationOptions: {
      refetchQueries: [VendorSettingsQuery],
    },
  })

  const handleSendInvitation = async (user) => {
    if (isInviteMutationLoading) {
      return
    }

    const variables = { id: user.id }
    inviteMutation({ variables }).then(({ data }) => {
      const responseData = data?.sendVendorUserInvitation || {}

      if (responseData?.entity) {
        newNotification({ success: t('userInvited') })
      }
    })
  }

  const userList = data?.vendor?.vendorUsers || []

  const colClasses = 'flex flex-col justify-center'
  const renderTitleChild = () => (
    <Button
      iconName="plusCircle"
      iconType="outline"
      label={t('addUser')}
      onClick={() => {
        setIsInviteUserModalOpen(true)
      }}
      size={sizes.BASE}
      testData="add-user-cta"
      variant={buttonsVariants.SECONDARY}
    />
  )
  const renderUsersList = () =>
    userList.map((user) => (
      <>
        <div className={cx(styles.listSection, styles.listSectionShort)}>
          <Text className={colClasses} size={sizes.SM}>
            {user.fullName || '--'}
          </Text>
        </div>
        <div className={cx(styles.listSection, styles.listSectionExtraShort)}>
          <Text className={colClasses} size={sizes.SM}>
            {t(user.membershipRole) || '--'}
          </Text>
        </div>
        <div className={cx(styles.listSection, styles.listSectionExtraOffset)}>
          <Tooltip content={user.email}>
            <Text className={colClasses} size={sizes.SM}>
              {user.email || '--'}
            </Text>
          </Tooltip>
        </div>
        <div className={cx(styles.listSectionFixed, styles.listSectionShort)}>
          <Text className={colClasses} size={sizes.SM}>
            {user.formattedPhoneNumber || '--'}
          </Text>
        </div>
        <div className={cx(styles.listSectionFixed, styles.listSectionShort)}>
          <Text className={colClasses} size={sizes.SM}>
            {user.formattedOfficePhoneNumber || '--'}
          </Text>
        </div>
        <div className={cx(styles.listSectionFixed, styles.listSectionShort)}>
          <Text className={colClasses} size={sizes.SM}>
            <FormattedDate date={user.lastLoggedAt} format="MM/dd/yyyy hh:mm:ss a" />
          </Text>
        </div>
        <div className={cx(styles.listIconSection, styles.listIconSectionShort)}>
          <UserActions
            onDelete={() => handleDeleteUser(user)}
            onEdit={() => handleEditUser(user)}
            sendInvitation={() => handleSendInvitation(user)}
            user={user || {}}
            userId={currentUser?.id}
            userMembershipRole={currentUser?.membershipRole}
          />
        </div>
      </>
    ))

  const closeForm = useCallback(() => {
    if (isEditUserModalOpen) {
      setIsEditUserModalOpen(false)
    } else if (isInviteUserModalOpen) {
      setIsInviteUserModalOpen(false)
    }
  }, [isEditUserModalOpen, setIsEditUserModalOpen, isInviteUserModalOpen, setIsInviteUserModalOpen])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  const renderModalContent = () => {
    if (isEditUserModalOpen) {
      const enabledFields =
        (editableUser.status === 'active' || editableUser.status === 'pending_onboarding') &&
        currentUser?.membershipRole === membershipRoles.ADMIN
          ? ['membershipRole']
          : null
      return (
        <EditUserForm
          closeForm={closeForm}
          enabledFields={enabledFields}
          isFormDirty={isFormDirty}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          setIsOpenedModal={setIsEditUserModalOpen}
          user={editableUser}
        />
      )
    }
    if (isInviteUserModalOpen) {
      return (
        <InviteUserForm
          closeForm={closeForm}
          isFormDirty={isFormDirty}
          refetch={refetch}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          setIsOpenedModal={setIsInviteUserModalOpen}
        />
      )
    }
  }
  const listColumns = useMemo(
    () => [
      {
        title: t('name'),
        className: cx(styles.listSection, styles.listSectionShort),
      },
      {
        title: t('role'),
        className: cx(styles.listSection, styles.listSectionExtraShort),
      },
      {
        title: t('email'),
        className: cx(styles.listSection, styles.listSectionExtraOffset),
      },
      {
        title: t('mobilePhone'),
        className: cx(styles.listSectionFixed, styles.listSectionShort),
      },
      {
        title: t('officePhone'),
        className: cx(styles.listSectionFixed, styles.listSectionShort),
      },
      {
        title: t('lastLogin'),
        className: cx(styles.listSectionFixed, styles.listSectionShort),
      },
      {
        title: '',
        className: cx(styles.listIconSection, styles.listIconSectionShort),
      },
    ],
    [t, styles],
  )

  return (
    <>
      <div>
        <Flex column>
          <Flex className="w" justifyContent="between" row>
            <Heading className="mb-4 text-xl" fontWeight={fontWeight.MEDIUM}>
              {t('teamManagement')}
            </Heading>

            {renderTitleChild()}
          </Flex>
          <div className="w-full">
            {loading && <LoadingSpinner />}
            {!loading && (
              <NarrowList columns={listColumns} listItems={renderUsersList()} withColumns />
            )}
          </div>
        </Flex>
      </div>
      {(isEditUserModalOpen || isInviteUserModalOpen) && (
        <Dialog
          isOpened={isEditUserModalOpen || isInviteUserModalOpen}
          setIsOpened={requestClose}
          title={isEditUserModalOpen ? t('editUser') : t('addUser')}>
          {renderModalContent()}
        </Dialog>
      )}

      <ConfirmationDialog
        confirmationMessage={
          <Text size={sizes.SM}>
            {t('removeUserDescription')}
            <Text color={colors.BLACK} fontWeight={fontWeight.BOLD} size={sizes.SM}>
              {' '}
              {editableUser?.fullName}
            </Text>
            ?
          </Text>
        }
        isOpened={isOpenedDeleteModal}
        isSubmitButtonYesDisabled={isArchiveMutationLoading}
        onSubmitButtonNoClick={() => setIsOpenedDeleteModal(false)}
        onSubmitButtonYesClick={handleArchive}
        setIsOpened={setIsOpenedDeleteModal}
        title={t('removeUser')}
      />
    </>
  )
}

export default TeamManagement
