import React, { useEffect, useMemo } from 'react'
import { Button, Flex, FormattedDate } from '../../ui-kit'
import sizes from '../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import { ErpFileExportsQuery } from '../../queriesUpdated/queries/erpFiles.gql'
import { CreateERPExport } from '../../queriesUpdated/mutations/createErpFileExport.gql'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { membershipRoles } from './teamManagement/membershipRoles'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import DataGridComponent from '../../components/dataGrid/DataGridComponent'
import DownloadFiles from '../../components/DownloadFiles'
import { useQueryParams } from '../../hooks/useQueryParams'
import { camelCase } from 'lodash'
import { getPaginationData, getTableParams } from '../../utils/utils'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { useNotifications } from '../../hooks/useNotifications'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'

const ErpFilesExport = () => {
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()
  useEffect(() => {
    setBreadcrumbs([
      { label: t('settings'), link: '/settings' },
      { label: t('erpFiles'), link: '/settings/erp_files' },
      { label: t('export'), link: '/settings/erp_files/export' },
    ])
  }, [])

  const filesMapping = (files) =>
    files.map((file) => ({
      url: file.document?.url,
      name: file.documentName,
    }))

  const { queryParams } = useQueryParams()
  const sort = queryParams.sort || 'created_at.desc'
  const { page } = getTableParams(queryParams)

  const pageSize = useMemo(() => 10, [])

  const currentUser = useCurrentUser()

  const queryVariables = useMemo(() => {
    return {
      page,
      sort,
      perPage: pageSize,
    }
  }, [sort, page, pageSize])

  const { data, loading } = useCustomQuery({
    query: ErpFileExportsQuery,
    queryOptions: {
      variables: queryVariables,
      skip: currentUser?.membershipRole !== membershipRoles.ADMIN,
    },
    rollbarOptions: { operationName: 'ErpFileExportsQuery', target: 'ErpFiles' },
  })

  const rows = data?.erpFileExports?.data || []
  const paginationData = getPaginationData(data?.erpFileExports)

  const columns = useMemo(
    () => [
      {
        field: 'createdAt',
        headerName: t('createdAt'),
        flex: 2,
        renderCell: (values) => (
          <FormattedDate date={values?.row?.createdAt} format={'MM/dd/yyyy hh:mm:ss a'} />
        ),
      },
      {
        field: 'link',
        headerName: t(''),
        flex: 2,
        renderCell: (values) => (
          <DownloadFiles files={filesMapping([values?.row])}>
            {(handleDownload) => (
              <Button
                label={t('download')}
                onClick={handleDownload}
                size={sizes.SM}
                variant={buttonsVariants.FOURTH}
              />
            )}
          </DownloadFiles>
        ),
        sortable: false,
      },
    ],
    [],
  )

  const [createErpFileExport, { loading: isErpFileExportLoading }] = useCustomMutation({
    mutation: CreateERPExport,
    rollbarOptions: { operationName: 'CreateERPExport', target: 'ERPFiles' },
    mutationOptions: {
      refetchQueries: [ErpFileExportsQuery],
    },
  })

  const { newNotification } = useNotifications()

  const handleCreateERPExport = () => {
    createErpFileExport().then(({ data }) => {
      const responseData = data?.createErpFileExport || {}
      if (responseData?.entity) {
        newNotification({ success: t('erpFileExportCreated') })
      }
    })
  }

  const defaultSortModel = useMemo(() => {
    const [field, direction] = sort.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])

  return (
    <div>
      <Flex justifyContent="end">
        <Button
          className="mb-4"
          isLoading={isErpFileExportLoading}
          label={t('createExportFile')}
          onClick={handleCreateERPExport}
          size={sizes.SM}
          testData="export-xlsx"
          variant={buttonsVariants.SECONDARY}
        />
      </Flex>
      <DataGridComponent
        columns={columns}
        hideFooter={paginationData.totalCount < 10}
        loading={loading}
        page={page}
        paginationData={paginationData}
        rows={rows}
        sortModel={defaultSortModel}
        disableSelectionOnClick
      />
    </div>
  )
}

export default ErpFilesExport
