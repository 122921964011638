import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import { InfoNote, Money, Text } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import React, { useCallback, useState } from 'react'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import { statusRefundMapping } from './util'
import InvoicesAccordion from '../../../ui-kit/components/invoicesAccordion/InvoicesAccordion'
import { getRefundTitle } from '../../invoices/invoiceRefund/reasonList'
import { statusRefundTransactionMapping } from '../paymentsTab/util'
import MemoField from '../../../ui-kit/components/inputs/MemoField'
import { UpdateInvoiceRefundMemo } from '../../../queries/mutations/updateRefundMemo.gql'
import { InvoiceRefundsQuery } from '../../../queries/invoiceRefunds.gql'
import { getSumByField } from '../../../utils/utils'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import Button from '../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { useNavigate } from 'react-router-dom'

const RefundContent = ({ data }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const descriptionData = [
    {
      label: t('status'),
      value: (
        <StatusBadge
          color={statusRefundMapping[data.status]?.color}
          value={t(statusRefundMapping[data.status]?.label)}
        />
      ),
    },
    {
      label: t('createdOn'),
      value: <FormattedDate date={data.createdAt} format={'MM/dd/yyyy hh:mm:ss a'} />,
    },
    { label: t('createdBy'), value: data.createdBy?.fullName },
    {
      label: t('customer'),
      node: (
        <Button
          label={data.contract?.buyer?.name}
          onClick={() => navigate(`/customers/${data.contract?.id}/overview`)}
          size={sizes.SM}
          testData="customer-link"
          variant={buttonsVariants.LINK}
        />
      ),
    },
    {
      label: t('underlyingInvoice'),
      value: data.invoice.invoiceNumber,
    },
    { label: t('reason'), value: getRefundTitle(data.reason) },
  ]
  const [memoFormIsDirty, setMemoFormIsDirty] = useState(false)

  const [updateInvoiceRefund, { loading }] = useCustomMutation({
    mutation: UpdateInvoiceRefundMemo,
    rollbarOptions: { operationName: 'UpdateInvoiceRefundMemo', target: 'RefundContent' },
    mutationOptions: {
      refetchQueries: [InvoiceRefundsQuery],
    },
  })

  const getRefundDetail = useCallback(
    () => (
      <table>
        <tbody>
          <tr>
            <th className="text-left">
              <Text color="text-black-500" fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                {t('paymentMethod')}
              </Text>
            </th>
            <th>
              <Text color="text-black-500" fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                {t('amount')}
              </Text>
            </th>
            <th>
              <Text color="text-black-500" fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                {t('status')}
              </Text>
            </th>
          </tr>
          {data?.refundPayableInvoiceAssignments?.map((item) => (
            <>
              {!!item.amountCents && (
                <tr key={item.id}>
                  <td className="text-left pt-4">{item.paymentMethod?.title}</td>
                  <td className="text-center pt-4">
                    <Money value={item.amountCents} />
                  </td>
                  <td className="text-center pt-4">
                    <StatusBadge
                      color={statusRefundTransactionMapping[item.status]?.color}
                      value={t(statusRefundTransactionMapping[item.status]?.label)}
                    />
                  </td>
                </tr>
              )}
              {!!item.depositUsages?.length && (
                <tr key={item.id}>
                  <td className="text-left pt-4">{t('deposits')}</td>
                  <td className="text-center pt-4">
                    <Money value={getSumByField(item.depositUsages, 'amountCents')} />
                  </td>
                  <td className="text-center pt-4">
                    <StatusBadge color="green" value="successful" />
                  </td>
                </tr>
              )}
              {!!item.creditUsages?.length && (
                <tr key={item.id}>
                  <td className="text-left pt-4">{t('credits')}</td>
                  <td className="text-center pt-4">
                    <Money value={getSumByField(item.creditUsages, 'amountCents')} />
                  </td>
                  <td className="text-center pt-4">
                    <StatusBadge color="green" value="successful" />
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    ),
    [data],
  )

  return (
    <div className="flex flex-col mt-[-1.5rem]">
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
            {t('refund')}
          </Text>
        </div>

        <div className="flex flex-row">
          <div className="mr-2">
            <InfoNote label={t('id')}>
              <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                {data.id}
              </Text>
            </InfoNote>
          </div>
        </div>
      </div>

      <div className="flex flex-row mt-3">
        <div className="w-6/12 flex flex-col">
          <SidebarLabel text="refundAmount" />
          <Money className="text-2xl mt-1 font-medium" value={data.amountCents} />
        </div>
      </div>

      <DividedList content={descriptionData} />
      <MemoField
        closeForm={() => null}
        entityId={data.id}
        initialValue={data.memo}
        isFormDirty={memoFormIsDirty}
        isLoading={loading}
        responseEntityName={'updateInvoiceRefund'}
        setDirtyFormState={setMemoFormIsDirty}
        updateEntity={updateInvoiceRefund}
      />
      <InvoicesAccordion className="mt-8" invoices={[data.invoice]} />
      <Text className="mt-5 mb-4" fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
        {t('refundDetails')}
      </Text>
      {getRefundDetail()}
    </div>
  )
}

RefundContent.propTypes = {
  data: PT.shape({
    id: PT.number,
    amountCents: PT.number,
    createdAt: PT.string,
    memo: PT.string,
    createdBy: PT.shape({
      fullName: PT.number,
    }),
    invoice: PT.object,
    project: PT.shape({
      name: PT.string,
    }),
    reason: PT.string,
    refundPayableInvoiceAssignments: PT.arrayOf(
      PT.shape({
        amountCents: PT.number,
        paymentMethod: PT.shape({
          title: PT.string,
        }),
        status: PT.string,
      }),
    ),
    status: PT.string,
    contract: PT.shape({
      id: PT.string,
      buyer: PT.shape({
        name: PT.string,
      }),
    }),
  }),
}

RefundContent.defaultProps = {
  data: {},
}

export default RefundContent
