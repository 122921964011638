export const otherReportsListMap = {
  ACTIVE_USERS: 'activeUsers',
  INACTIVE_USERS: 'inactiveUsers',
  ERP_OVERRIDES: 'erpOverrides',
}

export const otherReports = {
  [otherReportsListMap.ACTIVE_USERS]: {
    csvUrl: '/reports/buyer_users.csv?sort=last_login_date.desc',
    jsonUrl: '/reports/buyer_users.json?sort=last_login_date.desc',
    xlsxUrl: '/reports/buyer_users.xlsx?sort=last_login_date.desc',
    fileNameKey: otherReportsListMap.ACTIVE_USERS,
    defaultFilters: {
      status: 'active',
    },
  },
  [otherReportsListMap.INACTIVE_USERS]: {
    csvUrl: '/reports/buyer_users.csv?sort=last_invitation_date.desc',
    jsonUrl: '/reports/buyer_users.json?sort=last_invitation_date.desc',
    xlsxUrl: '/reports/buyer_users.xlsx?sort=last_invitation_date.desc',
    fileNameKey: otherReportsListMap.INACTIVE_USERS,
    defaultFilters: {
      status: 'invited',
    },
  },
  [otherReportsListMap.ERP_OVERRIDES]: {
    csvUrl: '/reports/erp_overrides.csv?sort=date.desc',
    jsonUrl: '/reports/erp_overrides.json?sort=date.desc',
    xlsxUrl: '/reports/erp_overrides.xlsx?sort=date.desc',
    fileNameKey: otherReportsListMap.ERP_OVERRIDES,
  },
}
